@include mainTheme(
	// $theme-name
	mainTheme,

	// $alpha
	#2c2276,

	// $beta
	#F6FBFF,

	// $gamma
	#0F257B,

	// $white
	#ffffff,

	// $psi
	#eeeeee,

	// $omega
	#00b1eb
);

@include mainTheme(
	// $theme-name
	colorBlind1,

	// $alpha
	desaturate(#2c2276, 20%),
	// $beta
	desaturate(#f9f9f9, 20%),
	// $gamma
	desaturate(#151515, 20%),
	// $white
	desaturate(#ffffff, 20%),
	// $psi
	desaturate(#eeeeee, 20%),
	// $omega
	desaturate(#00b1eb, 20%)
);

@include mainTheme(
	// $theme-name
	colorBlind2,

	// $alpha
	darken(#2c2276, 100%),
	// $beta
	lighten(#f9f9f9, 100%),
	// $gamma
	darken(#151515, 100%),
	// $white
	lighten(#ffffff, 100%),
	// $psi
	lighten(#eeeeee, 100%),
	// $omega
	desaturate(#00b1eb, 50%)
);
