@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {
  :global(.#{$theme-name}) {
    .card {
      background-repeat: no-repeat!important;
      background: url(./../../global/images/all-services-bg.png), linear-gradient(92.2deg, #E8F7FF 4.89%, #FCFAFE 99.64%)!important;
      .serviceInfo {
        font-family: Cairo-Bold;
        font-size: 2rem;
        color: $alpha;
      }

      .infoTitle {
        font-family: Cairo-Bold;
        font-size: 1rem;
        color: $alpha;
      }

      .infoData {
        font-family: Cairo-Bold;
        font-size: 1rem;
        color: #000F39;
      }

      .mediaCont {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: end;
        width: 100%;
      }

      .subTitle {
        padding: 6px 0px;
        color: #687588;
        font-family: Cairo-Regular;
      }
    }

    .formBorder {
      border-radius: 29px;
      border: 2px solid #10267C1A;
      padding: 3rem;
      @media (max-width: 767px) {
        padding: 1rem;

      }
    }

    .stepsButton{
      width: 148px;
      height: 50px;
      border-radius: 4px!important; 
      color: #1B2124;
      border: 1px solid #1B2124;
      font-family: Cairo-Bold;
      text-transform: capitalize;
      background: #fff;
    }

    .cancelButton {
      width: 148px;
      height: 50px;
      color: #E03137;
      font-family: Cairo-Bold;
      text-transform: capitalize;
      background: transparent;
    }

    .saveButton {
      width: 148px;
      height: 50px;
      border-radius: 4px!important; 
      color: #fff;
      border: 1px solid #1B2124;
      font-family: Cairo-Bold;
      text-transform: capitalize;
      background: #1B2124;
    }

    .fileUploaderCont {
      border: 1px dashed #00000040;
      border-radius: 10px;
      padding: 1rem;
      margin-top: 3rem;

      .labelCont {
        display: flex;
        align-items: center;
        @media (max-width: 1024px) {
          flex-wrap: wrap;
          text-align: center;
          justify-content: center;
        }
      }

      .fileButton {
        width: 140px;
        position: relative;
        cursor: pointer;
        margin-top: 10px;
        

        &.fileBtnEN {
          margin-left: auto;
          @media (max-width: 767px) {
            margin-left: 0;
          
          }
        }

        &.fileBtnAR {
          margin-right: auto;
          @media (max-width: 767px) {
            margin-right: 0;
          
          }
        }

        .fileContainer {
          position: relative;
        }

        .fileContainer > .signatureBtn, .fileButton {
          background-color: $omega;
          height: 50px;
          width: 140px;
          text-transform: capitalize;
          cursor: pointer;
          border-radius: 8px;
        }

        .signatureUploadBtn {
          background-color: $omega;
          height: 50px;
          width: 140px;
          cursor: pointer;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: space-evenly;

        }

        .file {
          z-index: 99;
          position: absolute;
          top: calc(50% - 1rem);
          opacity: 0;
          cursor: pointer;
          width: 145px;
          height: 50px;
          padding: 1rem 0;
        }
      }
    }

    .modal{
      z-index: 9999999999;

      > div > div {
        border-radius: 16px!important;
        padding: 3rem 2rem;
        color: #000;
        font-family: Cairo-Bold;
        text-align: center;
      }

      .modalBody {
        z-index: 9;
      }

      .modalButton {
        border-radius: 10px;
        font-family: Cairo-Bold;
        font-size: .8rem;
        width: 150px;
        height: 50px;
        border: 1px solid $alpha;

        &.homeBtn {
          background: $alpha;
        }

        &.dashBtn {
          color: $alpha;
          background: #fff;
        }
      }
    }

    .progressBarContainer{
      width: 100%;
      padding: 0 10px;
    }
  }
}

@import "global/style/theme.scss";

@mixin textOverflow {
    max-width: 100%;
    white-space: nowrap;
    text-overflow:  ellipsis ;
}

.numberIconContainer{
    display: flex;
    flex-direction: column;

    & span{
        cursor: pointer;
    
    }

    & button{

    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;

    &:nth-child(1){
        margin: -3px 0;
    }
    &:nth-child(2){
        margin: -3px 0;

    }
    }
    
}

.technicalSupportContainer{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 8%;
}

.technicalSupportButton{
  padding: 4px 11px 7px 15px !important;
  width: 148px;
  height: 40px;
  border-radius:  5px 25px 25px 25px  !important;
}

.nextPreviousContainer{
  display: flex;
  justify-content: space-between;
  gap: 10px;
  @media (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;

  }
}
.previousBtnWrapper {
  display: block;
  @media (max-width: 767px) {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;

  }
}

.imageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.thankYou{
  text-align: center;
  font-size: 26px;
}

.orderDelivered{
    color: #707070;
    text-align: center;
    font-size: 20px;
}

.buttonsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

  .greyColor{
    color: #B5B5C3;
  }

  .navyGreenColor{
    color: #4E6B31;
  }

  .w90{
    width:90%;
  }

  
.textLabel{
  padding: 0 1rem;
	font-family: Cairo-Bold;
  overflow: hidden;
  font-size: 16px;

}

.textExpandedLabel{
  padding: 0 1rem;
	font-family: Cairo-Bold;
  overflow: hidden;
  @include textOverflow();

}

.errMsg {
  color: #dc3545;
  font-weight: bold;
  font-size: 12px;
}

.requestWarning {
  background-color: #ffc107 !important;
    font-weight: 500;
    line-height: 1.2;
    color: #721c24;
    font-size: 1rem;
    border-radius: 5px;
    padding: 1rem;
    margin: 1rem 0;
}


.fileContainer{
  width: 100%;
  cursor: pointer !important;
  position: relative;


.file{
  z-index: 999;
  border-radius: 15px ;
  border-color: transparent ;
  background-color: #F5F5F5 ;
  box-sizing: border-box ;
  padding-left: 3rem ;
  padding: 5px;
  pointer-events: none;
}

.fileButton{
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(3%, 11%);
  width: 127px;
  height: 31px;
  background-color: #D0B166;
  border-radius: 15px;
  border: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  pointer-events: none;
  cursor: pointer;
}

// .signatureBtn{
//   position: absolute;
//   top: 0;
//   left: 0;
//   transform: translate(3%, 11%);
//   width: 127px;
//   height: 31px;
//   background-color: #D0B166;
//   border-radius: 15px;
//   border: none;
//   display: flex;
//   justify-content: space-evenly;
//   align-items: center;
//   color: white;
//   cursor: pointer;
//   z-index: 99;
// }

// .signatureUploadBtn {
//   position: absolute;
//   top: 0;
//   left: 0;
//   transform: translate(3%, 11%);
//   width: 127px;
//   height: 31px;
//   background-color: #D0B166;
//   border-radius: 15px;
//   border: none;
//   display: flex;
//   justify-content: space-evenly;
//   align-items: center;
//   pointer-events: none;
//   z-index: 999;
// }

.uploadIcon {
  border: none;
  background: transparent;
  pointer-events: none;
  z-index: 0;
}

.signatureIcon {
  border: none;
  background: transparent;
  z-index: 9999999999999;
  pointer-events: auto;
}


}



.disclaimr{
  padding: 0 1rem;
  color: 0000066;
  font-family: Cairo-Regular;
  font-size: 0.65rem;
  font-size: 11px;
  & span {
  margin: 0 0.2rem;
  }
}

  .fileContent {
    font-size: 0.6rem !important;
    color: #000000B2;
    font-family: Cairo-Bold;
    margin-top: 5px;

    &.fileType {
      width: 50px;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &.clientRequestInfo {
      word-wrap: break-word;
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }


  .files {
    margin-top: 1rem;
    padding: 1rem;
    background: #fff;
    padding: 0.25rem 0;
    border-radius: 0.25rem;
    word-wrap: break-word;
    word-break: break-all;

    &.clientRequestInfo {
      background: #f8f8f8;
      flex-wrap: wrap;
    }
  }




  .greyWave{
    position: absolute;
top: 3%;
scale: 1.07;
  }

 

  
  .tSupportBtn {
    height: 2.5rem;
    width: auto;
    font-size: 0.8rem;
}

.label {
  font-size: 0.8rem;
  padding: 0 15px;
  text-align: center;
  font-family: Cairo-Bold;
  width: 100%;
}
