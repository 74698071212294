@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {
  :global(.#{$theme-name}) {
    .siginIn {
      border-radius: 4px;
      height: 41px;
      background: $white;
      border: none;
      color: #2D405F;
      // color: $gamma;
      font-size: 0.8rem;
      padding: 0 20px;
      font-family: Cairo-Bold;

      @media (max-width: 992px) {
        height: 35px;
        font-size: 0.7rem;
        padding: 0 20px;
      }

      @media (max-width: 500px) {
        height: 30px;
        font-size: 0.6rem;
        padding: 0 15px;
        width: 100%;
      }

      &.signAr {
        margin-right: 10px;

        @media (max-width: 992px) {
          margin-right: 8px;
        }
      }

      &.signEn {
        margin-left: 10px;

        @media (max-width: 992px) {
          margin-left: 8px;
        }
      }
    }

    .profileType, .profileStatus {
      font-family: Cairo-Bold;
      font-size: 0.7rem;
    }

    .profileType {
      color: #68F0AC;
      margin-top: 5px;
    }

    .verif {
      display: flex;
      align-items: center;
      padding-bottom: 4px;

      .verifLine {
        width: 30%;
        height: 3px;
        border-radius: 5px;
        margin: 0 3px;
      }

      .profileStatus {
        max-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
      }

      &.profileActive {
        .verifLine {
          background: #68F0AC;
        }

        .profileStatus {
          color: #68F0AC;
        }
      }

      &.profilePending {
        .verifLine {
          background: #ffc107;
        }

        .profileStatus {
          color: #ffc107;
        }
      }

      &.profileInactive {
        .verifLine {
          background: #e42829;
        }

        .profileStatus {
          color: #e42829;
        }
      }
    }

    .userWrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      z-index: 9999;
      line-height: 13px;
      align-items: center;
      height: 100%;
      width: 100%;
      margin: 0px 5px;

      > div {
        padding: 0px 8px;
        // padding: 12px 8px;
        display: flex;
        flex-direction: column;
        color: #fff;
        line-height: 10px;
      }

      @media(max-width: 768px) {
        margin: 0 15px;
        min-width: 100px;
        height: 40px;
      }
    }

    .menuItem {
      font-size: 0.8rem;
      font-family: Cairo-Bold;
      cursor: pointer;
      height: 100%;
      z-index: 99999999;

      @media (max-width: 992px) {
        font-size: 0.7rem;
      }

      > a {
        color: inherit;
        text-decoration: none;
        font-family: inherit;
        width: fit-content;
        text-align: center;
        height: 100%;
        display: flex;
        align-items: center;
        // padding: 0 7.5px;
        margin: auto;
        width: 200px;

        @media (max-width: 768px) {
            width: 200px;
        }

        &:global(.active),
        &.activeLink {
          color: $alpha;
        }
      }
    }

    :global(.notifOverlayStyle) { 
      background-color: transparent!important; 
      z-index: 999!important;
    }

    :global(.sideBarStyle) {
      background-color: white!important;
      position: fixed!important;
      width: 400px;
      max-width: 100%;
      z-index: 9999999999 !important;
      box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 30px!important;
      overflow: hidden;
    }

    :global(.sideBarEnStyle) { 
      left: 0px!important; 
      right: unset!important; 
    }

    :global(.sideBarArStyle) { 
      right: 0px!important; 
      left: unset!important; 
      width: 400px!important;

      &:global(.sideBarArCloseStyle) {
        transform: translateX(100%)!important;
      }
    }

    .siderbarBtn {
      position: absolute;
      // height: 100%!important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      z-index: 99999999;
      border: 1px solid #fff;
      border-radius: 5px;
      height: 38px;
      width: 38px;
      top: 50%;
      transform: translateY(-50%);

      &.siderbarEnBtn {
        right: 4px;
      }

      &.siderbarArBtn {
        left: 4px;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .Badge {
      font-size: 10px;
      font-family: Cairo-Bold;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: absolute;
      right: -4px;
      bottom: 50%;
      color: #fff;
      padding: 0;
      width: 20px;
      height: 20px;
      z-index: 999;

      &.newBadge {
        background-color: $omega;
      }

      &.unreadedBadge {
        background-color: $omega;
      }
    }

    .userImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }
}
  
@import "./../../../../../global/style/theme.scss";
  