@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {
  :global(.#{$theme-name}) {
    .progressBarStyle {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      height: 0.5rem;
      font-size: 0.5rem;
      color: $omega;
    }
  }
}

@import "./../../../global/style/theme.scss";

