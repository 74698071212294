
@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .footer {
            background-color: $gamma;
            font-size: 1rem;
            padding: 6rem 0 1rem;
            color: $white;
            background-image: url(./../../../global/images/footerImg.png);
            background-repeat: no-repeat;
            background-size: contain;
            font-family: Cairo-Regular;

            &.imgLeft {
                background-position: left;
            }

            &.imgRight {
                background-position: right;
            }

            @media (max-width: 767px) {
                padding-top: 5rem;

                &.addMargin {
                    margin-bottom: 60px;
                }
            }

            .privacyCont {
                color: #B8B8B8;
                display: flex;

                span, a {
                    color: #B8B8B8;
                    padding: 0 10px;
                    font-size: 12px;
                    cursor: pointer;
                    height: 16px;
                    display: block;

                    &:hover {
                        text-decoration: none;
                        cursor: pointer;
                    }
                }

                &.borderLeft {
                    span {
                        border-left: 1px solid #B8B8B8;
                        &:first-child {
                            border: none;
                        }
                    }
                }

                &.borderRight {
                    span {
                        border-right: 1px solid #B8B8B8;
                        &:first-child {
                            border: none;
                        }
                    }
                }
            }

            .contactCont {
                display: flex;
                align-items: center;
                justify-content: end;
                font-size: 16px;
                @media (max-width: 767px) {
                    margin-top: 20px;
                    justify-content: space-between;

                }
                a , a:hover {
                    color: #fff;
                    text-decoration: none;
                }
            }

            .lineDivider {
                border-top: 1px solid #858585;
                margin: 20px -15px;
            }

            .newsLetter {
                @media (max-width: 767px) {
                    text-align: center;
                }
                span {
                    padding: 0 10px;
                    cursor: pointer;

                    a , a:hover {
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }

            .footerTitle {
                font-size: 48px;
                margin-bottom: 55px;
                font-family: Cairo-Bold;
                @media (max-width: 767px) {
                    font-size: 36px;
                 }
            }

            .copyRights {
                text-align: center;
                p {
                    margin: 0;
                    padding: 15px 0;
                    font-size: 14px;
                }
            }
        }

        .socialMedia {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            // justify-content: center;
            flex-wrap: wrap;

            @media (max-width: 767px) {
                justify-content: center;
            }

            li:first-child {
                a {
                    span {
                        svg {
                            width: 2rem;
                            height: 1rem;
                        }
                    }
                }
            }

            // li:last-child {
            //     a {
            //         span {
            //             svg {
            //                 width: 1rem;
            //                 height: 1rem;
            //             }
            //         }
            //     }
            // }

            li {
                margin: 5px 5px 0px 5px;
                padding: 5px 8px;

                a, svg {
                    display: flex;
                    align-items: center;
                    height: 100%;
                }

                span {

                    svg {
                        width: 2rem;
                        height: 2.2rem;

                        path {
                            fill: $white;
                        }
                    }
                }

                .homeIcon {
                    svg {
                        display: flex;
                        align-items: center;
                        height: 100%;
                        width: 1.2rem;
                        // height: 1.2rem;
                    }
                }
            }
        }

        .privacyPage {
            margin: 4rem auto;

            .mainTitle {
                font-size: 2rem;
                font-family: Cairo-Bold;
                color: $alpha;
            }

            .subTitle {
                color: #00AAE3;
                font-size: 1.5rem;
                font-family: Cairo-Bold;
            }

            .boldText {
                color: #000;
                font-size: 1.2rem;
                font-family: Cairo-Bold;
            }

            .heading {
                color: $alpha;
                font-size: 1.5rem;
                font-family: Cairo-Bold;
            }

            .coloredHeading {
                color: #00AAE3;
                font-size: 1.2rem;
                font-family: Cairo-Bold;
            }

            .rightsPermissionsList {
                list-style: none;
            }

            .depRolesList {
                list-style: disc;
            }
        }
    }
}

@import './../../../global/style/theme.scss'
