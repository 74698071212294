@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .servicesCard {
            // height: 22.27rem;

            .tabsContainer {
                background-color: $alpha;
                padding: 0;

                &.tabsContainerEn {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                }

                &.tabsContainerAr {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }

                ul {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    display: block;

                    @media (max-width: 575px) {
                        display: flex;
                        justify-content: center;
                    }

                    li {
                        a {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            color: $white;
                            height: 2.7rem;
                            margin: 1.2rem 0;
                            cursor: pointer;

                            &:hover {
                                text-decoration: none;
                                color: $white;
                            }

                            &.activeTab {
                                background: darken($alpha, 4%);
                                color: $white;
                                text-decoration: none;
                            }

                            &.disabledTab {
                                cursor: default;
                                opacity: 0.2;
                            }

                            span {
                                &.spanEn {
                                    padding: 0 0.8rem 0 1.1rem;
                                }

                                &.spanAr {
                                    padding: 0 1.1rem 0 0.8rem;
                                }

                                svg, .dealerImg {
                                    width: 1rem;
                                    height: 1rem;
                                }
                            }

                            p {
                                font-size: 1rem;
                                font-family: Cairo-Regular;
                                margin-bottom: 0;
                            }

                            @media (max-width: 575px) {

                                margin: 1rem 0;
                                padding: 0 10px;

                                p {
                                    display: none;
                                }

                                span {
                                    padding: 0 5px!important;
                                }

                                &.activeTab p {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }

            .servicesListContainer {
                background: $white;
                display: flex;
                flex-direction: column;
                overflow: hidden;

                &.servicesListContainerEn {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }

                &.servicesListContainerAr {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                }

                @media (max-width: 991px) {
                    box-shadow: 0 3rem 3rem rgba(0,0,0,.1);
                }

                .searchInput {
                    margin-top: 1.1rem;
                    margin-bottom: 2.5rem;
                    font-family: Cairo-Regular;

                    &.searchInputEn {
                        input {
                            padding-left: 45px;
                        }
                    }

                    &.searchInputAr {
                        input {
                            padding-right: 45px;
                        }
                    }

                    input {
                        background-color: #F3F6FF;
                        border-color: transparent;
                        font-size: 0.8rem;
                        height: 3rem;
                    }

                    .searchIcon {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);

                        &.searchIconAr {
                            right: 30px;
                        }

                        &.searchIconEn {
                            left: 30px;
                        }

                        svg {
                            width: 1.3rem;
                            path {
                                fill: #8D8D8D;
                            }
                        }
                    }
                }

                .paginationCont {
                    margin-top: auto;
                    margin-bottom: 2.5rem;
                }

                .servicesCont {
                    min-height: 215px;

                    @media (min-width: 768px) {
                        // min-height: 350px;
                    }

                    @media (min-width: 992px) {
                        // min-height: 320px;
                    }
                }
            }

        }

    }
}

@import './../../../../global/style/theme.scss'
