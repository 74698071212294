@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

  :global(.#{$theme-name}) {

    .paragraph {
      text-align: center;
      font-size: 0.875rem;
      font-family: Cairo-Bold;
      width: 100%;
      margin: 0;
      padding: 0;
    }
    .highlight {
      color: $omega;
      cursor: pointer;
      font-size: 0.875rem;
      font-family: Cairo-Bold;
    }
    .highlight:hover {
      text-decoration: underline;
    }
    .btn {
      margin: 0;
      text-transform: none;
      -webkit-appearance: button;
      background-color: transparent;
      background-image: none;
      cursor: pointer;
      border-width: 0;
      border-style: solid;
      text-decoration: none!important;
    }
    .btn::-moz-focus-inner,
    [type="button"]::-moz-focus-inner,
    [type="reset"]::-moz-focus-inner,
    [type="submit"]::-moz-focus-inner {
      border-style: none;
      padding: 0;
    }

    .btn:-moz-focusring,
    [type="button"]:-moz-focusring,
    [type="reset"]:-moz-focusring,
    [type="submit"]:-moz-focusring {
      outline: 1px dotted ButtonText;
    }
  }
}

@import './../../../global/style/theme.scss'

