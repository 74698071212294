@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .menuList {
            display: flex;
            list-style: none;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            padding: 0;
            margin: 0;
            width: 100%;
            // flex: 0 0 50%;

            @media (max-width: 1024px) {
                flex: 0 0 100%;
            }

            // @media (max-width: 767px) {
            //     overflow-y: scroll;
            //     top: 50px;
            //     right: 0;
            //     left: 0;
            //     width: 100%;
            // }

            .respMenuContainer {
                @media(max-width: 768px) {
                    overflow: scroll;
                    display: flex;
                    flex-direction: column-reverse;
                    margin-bottom: auto;
                    margin-top: 0!important;
                }

                @media(min-width: 768px) {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                }
            }

            .menuItemDiv {
                @media (min-width: 768px) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 35%;
                }

                @media (max-width: 768px) {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    // width: 60%;
                    // margin: auto;
                    margin: 1rem 3rem;
                }
            }

            .menuItem {
                font-size: 0.9rem;
                font-family: Cairo-Regular;
                cursor: pointer;
                height: 100%;
                z-index: 9999;
                color: $white;

                @media (max-width: 992px) {
                    font-size: 0.7rem;
                    > a {
                        color: $gamma;
                        
                    }
                }

                > a {
                    color: #fff;
                    text-decoration: none;
                    font-family: inherit;
                    width: fit-content;
                    text-align: center;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    padding: 0 7.5px;
                    margin: auto;

                    &:global(.active), &.activeLink {
                        // color: $alpha;
                    }

                    // &:hover {
                    //     background-color: $psi;

                    //     @media (max-width: 768px) {
                    //         background: transparent;
                    //     }
                    // }
                }

                .activeMunicipal {
                    color: $omega;
                    padding: 0 3px;
                }
            }

            @media (max-width: 768px) {
                background: $gamma;
                position: absolute;
                // flex-direction: column-reverse;
                z-index: 99;
                box-shadow: 0 1rem 1rem rgba(0,0,0,.15)!important;
                padding: 1rem 0;
                visibility: hidden;
                opacity: 0;
                height: 100vh!important;
                align-items: center;
                justify-content: flex-end;
                width: 60vw;
                overflow: scroll;
                padding-bottom: 5rem;

                &.respOpen {
                    visibility: visible;
                    opacity: 1;
                    transition: all .5s ease-in-out;
                    padding-top: 2rem;
                }
              

                .menuItem {
                    margin: .3rem 0;
                    padding: .4rem 0;

                    a {
                        padding: .5rem;

                        &.activeLink {
                            background: $psi;
                        }
                    }
                }
            }
            @media (max-width: 768px) {
                &.respOpenAr {
                    visibility: visible;
                    opacity: 1;
                    transition: all .5s ease-in-out;
                    top: 60px;
                    left: 40vw;
                }

                &.respOpenEn {
                    visibility: visible;
                    opacity: 1;
                    transition: all .5s ease-in-out;
                    top: 60px;
                    right: 40vw;
                }
            }

            @media (max-width: 591px) {
                    width: 80vw;

                &.respOpenAr {
                    left: 20vw;
                }

                &.respOpenEn {
                    right: 20vw;
                }
            }
        }

        .dropdownOverlay {
            position: fixed;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 99;
            top: 0;
            bottom: 100%;
            background-color: red;

            @media (max-width: 768px) {
                display: none!important;
            }
        }

        .barContainer {
            height: 100%;
            align-items: center;
            display: none;
            z-index: 99;

            span {
                padding: 0 .5rem;
                cursor: pointer;

                svg {
                    width: 1.2rem;
                    height: 1.2rem;

                    path {
                        fill: $gamma;
                    }
                }
            }

            @media (max-width: 768px) {
                display: flex;
            }
        }

        .mobileMenuOverlay {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100vh;
            width: 100vw;
            background: #0f257b59;
        }

        @media (max-width: 768px) {
            .barContainer {
                justify-content: flex-end;
                span {
                    svg {
                        path {
                            fill: #FFF;
                        }
                    }
                }
            }
        }
    }
}

@import './../../../../../global/style/theme.scss'
