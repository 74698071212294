@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .paraContainer {
            padding-top: 8.3rem;

            @media (max-width: 575px) {
                padding-top: 4.3rem;
            }
        
            .title {
                font-size: 1.7rem;
                // font-family: GE_SS_Two_Bold;
                font-family: Cairo-Bold;
            }
        
            .para {
                font-size: 1.2rem;
                // font-family: GE_SS_Two_Medium;
                font-family: Cairo-Bold;
            }

            .forInfo {
                font-size: 1rem;
                // font-family: GE_SS_Two_Medium;
                font-family: Cairo-Bold;
                margin-bottom: 5px;
            }
            
            .websiteURL {
                color: $alpha;
                text-decoration: none;
            }
        }

    }
}

@import './../../../../global/style/theme.scss'


