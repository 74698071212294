@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .socialMedia {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            // @media (max-width: 767px) {
            //     justify-content: center;
            // }

            li:first-child {
                a {
                    span {
                        svg {
                            width: 2rem;
                            height: 1rem;
                        }
                    }
                }
            }

            li {
                margin: 5px 5px 0px 5px;
                padding: 5px 8px;

                a, svg {
                    display: flex;
                    align-items: center;
                    height: 100%;

                    path {
                        fill: $omega;
                    }
                }

                span {

                    svg {
                        width: 2rem;
                        height: 2.2rem;

                        path {
                            fill: $omega;
                        }
                    }
                }

                .homeIcon {
                    svg {
                        display: flex;
                        align-items: center;
                        height: 100%;
                        width: 1.2rem;
                        // height: 1.2rem;
                    }
                }
            }
        }

        .dashboardData {

            

            .name {
                font-family: Cairo-Bold;
                font-size: .9rem;
                color: $alpha;
                margin: 0;
                padding: 8px 0;
                text-align: center;
                width: 100%;
                background: $beta;
                border-radius: 10px;
                border: 1px solid $omega;
            }

            .file {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: .7rem;
                margin: .5rem 0 .5rem;
                box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;

                p {
                    padding: 0;
                    font-size: 0.8rem!important;
                    margin: 2px 5px!important;
                }

                .fileSvg {
                    height: 1.6rem;
                    width: 1.6rem;
                }

                svg {
                    padding: 4px 0;

                    path {
                        fill: $alpha;
                    }
                }

                .download {
                    cursor: pointer;

                    &.downloadAR {
                        margin-right: auto;
                    }

                    &.downloadEN {
                        margin-left: auto;
                    }
                }
            }

            .confirmDelete {
                font-family: Cairo-Bold;
                font-size: 1.1rem;
                text-align: center;
            }

            // .checkbox {
            //     font-weight: 700;
            //     color: $alpha;
            // }

            .dashboardField {
                background: $beta;
                border: none;
                font-size: 0.7rem;
                height: 2.5rem;
                padding: 0.5rem;
                outline: transparent;
                margin-bottom: .5rem;
                text-align: center;
                height: auto;
                overflow: hidden;
                resize: none;
                width: 100%;
                border-radius: 10px;

                &.editable {
                    font-family: Cairo-Bold!important;
                    font-size: 0.7rem!important;
                }

                &:read-only {
                    font-size: 0.9rem!important;
                    font-family: Cairo-Bold;
                    padding-top: 0;
                    background: $beta;
                    margin-top: 5px;

                    &:global(.#{title}) {
                        font-family: Cairo-Bold;
                        font-size: 1.4rem;
                        margin: .5rem auto 1rem;
                        padding: 2rem;
                        background: $beta;
                        color: $alpha;
                    }
                }
            }
        }

        .sendBtn:disabled {
            opacity: .5;
            cursor: not-allowed;
        }

        .SharjahLogo {
            width: 60px;
            margin-bottom: 1rem;
        }

        .SharjahLogoTitle {
            margin-bottom: 0;
            position: relative;
            color: $alpha;
            font-family: Cairo-Bold;
            font-size: .8rem;
        }

        .SharjahLogoTitle:before {
            content: "";
            border: 1px solid $omega;
            position: absolute;
            width: 20%;
            top: 50%;
            right: 22%;

            @media (max-width: 993px) {
                display: none;
            }
        }

        .SharjahLogoTitle:after {
            content: "";
            border: 1px solid $omega;
            position: absolute;
            width: 20%;
            top: 50%;
            left: 22%;

            @media (max-width: 993px) {
                display: none;
            }
        }

        .logoLine {
            border: 1px solid $omega;
            width: 55%;
            margin: 1rem auto;

            @media (min-width: 993.5px) {
                display: none;
            }
        }

        .line {
            border: 1px solid $omega;
            width: 55%;
            margin: 2rem auto;
        }

        .dashboardNewModel {
            margin-top: 2rem;
            margin-bottom: 2rem;

            .label {
                color: $gamma;
                background: #efd7a0;
                border: 2px solid $omega;
                border-radius: 5px;
                font-family: Cairo-Bold;
                font-size: .8rem;
                margin-bottom: 0;
                width: 50%!important;
                padding: 7px;
            }

            .labelTitle {
                color: $alpha;
            }

            .data {
                margin: 0 10px;
                border: 2px solid $psi;
                border-radius: 5px;
                font-family: Cairo-Bold;
                font-size: .8rem;
                padding:7px;
                width: 100%;
            }

            .dateAR {
                direction: ltr;
                text-align: end;
            }

            .file {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                p {
                    padding: 0;
                    font-size: 0.8rem!important;
                    margin: 2px 5px!important;
                }

                .fileSvg {
                    height: 1.6rem;
                    width: 1.6rem;
                }

                svg {
                    padding: 4px 0;

                    path {
                        fill: $alpha;
                    }
                }

                .download {
                    cursor: pointer;

                    &.downloadAR {
                        margin-right: auto;
                    }

                    &.downloadEN {
                        margin-left: auto;
                    }
                }
            }

            .gridStyle {
                :global(.dx-gridbase-container) {
                    background-color: transparent;
                }

                :global(.dx-row.dx-header-row) {
                    color: $gamma;
                    background-color: #efd7a0;
                    border: 2px solid $omega;
                    border-radius: 5px;
                    font-size: .8rem;
                }

                :global(table.dx-datagrid-table.dx-datagrid-table-fixed) {
                    border-collapse: collapse!important;
                }

                :global(.dx-datagrid.dx-gridbase-container) {
                    padding: 1rem 0!important;
                }

           
           
            }

     
            .secondaryNewBtn {
                border-radius: 25px 25px 25px 5px !important;
            }

            .primryNewBtn {
                border-radius: 25px 5px 25px 25px !important;
            }
        }

        .Tahseel, .ShjMunUniPortal {
            background: #000;
        }

        .Visa, .MasterCard, .Tahseel {
            width: 130px;
            height: 90px;
            object-fit: contain;
        }

        .MasterCard {
            background: #000066;
        }

        .ShjMunUniPortal {
            height: 278px;
            width: 100%;
        }

        .Tahseel, .ShjMunUniPortal {
            padding: 8px;
        }

        .newContainer {
            .title {
                color: $alpha;
                font-family: Cairo-Bold;
                margin-bottom: 5px;
            }

            .data, .data:focus, .data:disabled {
                margin: 0 10px 0 0;
                border: 2px solid $psi;
                border-radius: 5px;
                font-family: Cairo-Bold;
                font-size: .8rem;
                padding:7px;
                // width: 100%;
                width: 60%;
                outline: none;
                overflow-x: scroll;
                color: $gamma;
                opacity: 1;
            }

            .msg {
                color: red;
                font-size: .89rem;
            }

            .label {
                font-size: 0.85rem;
                font-family: Cairo-Bold;
                background: $psi;
                padding: 0.5rem;
                border-radius: 5px;
                color: $alpha;
                width: 40%;
                margin-bottom: 0;
            }

            .titleLabel {
                color: #58743A;
                font-size: 0.7rem;
                font-family: Cairo-Bold;
            }

            .primryNewBtn {
                border-radius: 25px 5px 25px 25px !important;
            }

            .valueTitle, .valuePaidBy, .payer, .shareLinkVia {
                font-size: 0.7rem;
                font-family: Cairo-Bold;
                margin-bottom: 0;
            }

            .value {
                font-size: 1.4rem;
                font-family: Cairo-Bold;
            }

            .sector {
                font-size: .9rem;
                font-family: Cairo-Bold;
                margin-bottom: 0;
            }

            .section {
                font-size: 1rem;
                font-family: Cairo-Bold;
            }

            .payer {
                border: none;
                text-align: center;
                margin: 0;
                padding: 0;
            }

            .icons {
                width: 35px;
                height: 35px;
                padding: 5px;
                cursor: pointer;

                svg {
                    width: 1.2rem;
                    height: 1.2rem;
                }

                path:last-child {
                    fill: $omega
                }
            }

            .departmentLogo {
                height: 6rem;
                margin-bottom: 1rem;
            }

         
        }

        .adjustPadding{
            padding: 0 10px!important;
        }

   
       
    }
}



@import './../../../global/style/theme.scss'

