@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .housingModels {

            &.backWrap {
                // padding-bottom: 8rem;
                // padding-top: 4rem;
                position: relative;
                background: url('./../../../global/images/Rectangle\ 2.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 40rem;
            }

            .title1 {
                padding-top: 10rem;
                font-size: 1.6rem;
                font-family: Cairo-Bold;
                margin-bottom: 2rem;
            }
            
            .title2 {
                font-size: 2rem;
                font-family: Cairo-Bold;
                margin-bottom: 5.5rem;
            }

            .searchCard {
                background: #fff;
                width: 100%;
                border-radius: .6rem;
                padding: 3.8rem 5rem;
                // box-shadow: 15px 0px 30px 0px rgba(228, 228, 228, 0.5);
                box-shadow: 15px 0px 30px 0px rgba(228, 228, 228, 0.7);
                margin-bottom: 5.5rem;

                @media (max-width: 576px) {
                    padding: 2rem;
                }

                
                .label {
                    font-size: 1.2rem;
                    font-family: Cairo-Bold;

                    @media (max-width: 576px) {
                        text-align: center;
                    }

                    span {
                        svg {
                            path {
                                fill: $alpha;
                            }
                        }
                    }
                }
                

                .input {
                    margin-bottom: 1.4rem;
                    margin-top: 1rem;
                    height: 3rem;
                    border-radius: 0.2rem;
                    outline: none;
                    border: 1px solid #CACACA;
                    padding: 0 8px;

                    @media (max-width: 768px) {
                        margin-top: 0;
                    }
                }

                .numbers {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;

                    @media (max-width: 576px) {
                        justify-content: center;
                    }

                    .number {
                        width: 44px;
                        min-width: 44px;
                        max-width: 44px;
                        height: 44px;
                        flex-basis: 100%;
                        color: #737A6B;
                        border-radius: 50%;
                        border: 1px solid #737A6B;
                        margin: 5px;
                        padding: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;

                        &.active {
                            background: $alpha;
                            color: #fff;
                        }
                    }
                }
            }
        }

        .cardsWrapper {
            min-height: 500px;
            padding-top: 14rem;
            padding-bottom: 10rem;
            background: #F9F9F9;

            @media (max-width: 991px) {
                padding-top: 28rem;
            }
            @media (max-width: 767px) {
                padding-top: 37rem;
            }
            
        }

        .detailsTitle {
            margin-bottom: 2rem;
            margin-top: 4rem;

            .title {
                font-size: 1.3rem;
                font-family: Cairo-Bold;
                margin-bottom: 0;
            }

            .download {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .downloadIcon {
                    cursor: pointer;
                    padding: .5rem 1rem;
                    svg {
                        fill: #56753C;
                    }
                }
            }
        }

        .details {
            margin-bottom: 3.8rem;

            .detailRow {
                font-size: 1rem;
                font-family: Cairo-Bold;
                padding-bottom: 1rem;
                padding-top: 1rem;
    
                &.coloredRow {
                    background: #F9F9F9;
                }
            }
        }

        .header {
            margin-top: 4rem;
            margin-bottom: 1.3rem;

            .title {
                font-size: 1.4rem;
                font-family: Cairo-Bold;
                color: inherit;
                // margin-bottom: 0;

                a, a:hover {
                    text-decoration: none;
                    color: inherit;
                }
            }
        }

        .sliderIcons {
            border: 1px solid #121212;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            height: 58px;
            width: 58px;
            cursor: pointer;
            margin: 0 1rem;

            svg {
                path {
                    fill: #121212;
                }
            }

            &.sliderIconsActive {
                background: $alpha;
                border: 1px solid #fff;

                svg {
                    path {
                        fill: #fff;
                    }
                }
            }
        }

        :global(.slider) {

            :global(.image-gallery-swipe) {
                width: 100%;
            }
            :global(.image-gallery-slide) {
                max-height: 552px!important;
                height: 519px;
                background: center center url(../../../global/images/housing_fall_back.jpg);

                @media (max-width: 768px) {
                    height: auto;
                }
            }
            :global(.image-gallery-image) {
                object-fit: cover;
            }

            :global(.image-gallery-thumbnail.active),
            :global(.image-gallery-thumbnail:focus),
            :global(.image-gallery-thumbnail:hover),
            :global(.image-gallery-thumbnail) {
                // border: none!important;
                border-color: transparent!important;
            }

            :global(.image-gallery-fullscreen-button) {
                left: 0!important;
                right: auto;
            }

            :global(.image-gallery-content.bottom.fullscreen) {
                height: 100%;
            }

            :global(.image-gallery-slide-wrapper.bottom) {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            :global(.image-gallery-thumbnail-image) {
                object-fit: cover;
                width: 200px;

                @media (max-width: 768px) {
                    width: 100px;
                }
            }

            :global(.image-gallery-thumbnails-container) {
                display: flex!important;
                justify-content: center!important;
            }

            :global(.image-gallery-thumbnail) {
                width: auto;
            }
        }
    }
}

@import './../../../global/style/theme.scss'