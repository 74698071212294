.imageWrapper {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  max-height: 200px;
  border-radius: 9999px;
  background: #f3f5f1;
  font-family: Cairo-Bold;
}
.imageInput {
  display: none;
}
