@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .searchResults {
            font-size: 0.8rem;
            font-family: Cairo-Bold;
            color: #3232325c;
            margin: 0;
        }

        .decision {
            padding-top: 25px;
            padding-bottom: 25px;
            border-bottom: 1px solid #EDEDED;

            &:last-child {
                border-bottom: none;
            }

            .decisionTitle {
                font-size: 0.9rem;
                font-family: Cairo-Bold;
                margin-bottom: 10px;
            }
        
            .decisionDes {
                font-size: 0.8rem;
                font-family: Cairo-Bold;
                margin-bottom: 8px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        .downloadTitle {
            margin-bottom: 0;
            font-size: 0.8rem;
            font-family: Cairo-Bold;
            color: $alpha;
            text-decoration: none;
            padding: 0 .5rem;
        }

        .download {
            display: flex;
            align-items: baseline;
            width: fit-content;
            cursor: pointer;

            svg {
                width: 1.3rem;
                height: 1.3rem;

                path {
                    fill: $alpha
                }
            }
        }

        .tabTitle {
            font-family: Cairo-Bold;
            font-size: 0.88rem;
            margin-bottom: 6px;
        }

        .tabList {
            list-style: none;
            padding: 0;
            width: 100%;

            .activeTab {
                background-color: #F3F3F3;
                border-radius: 20px;

                .tab {
                    color: #58743A;
                }
            }

            .tab {
                color: inherit;
                text-decoration: none;
                display: block;
                cursor: pointer;
                font-family: Cairo-Bold;
                font-size: 0.77rem;
                padding: 7px 10px;
                text-transform: capitalize;
            }
        }

        .activeCategory {
            font-size: 0.8rem;
            font-family: Cairo-Bold;
            background: #EEF3E8;
            padding: 0.5rem 1rem;
            border-radius: 20px;
            color: #58743A;
        }

        .input {
            display: block;
            width: 100%;
            padding: .375rem .75rem;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            outline: none;
            border-radius: 2rem !important;
            height: 3rem !important;
            border-color: #E9E9E9 !important;
            font-size: 0.8rem !important;
            margin: .5rem 0;
        }

        .searchIcon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &.searchIconAr {
                left: 2rem;
            }

            &.searchIconEn {
                right: 2rem;
            }

            svg {
                width: 1.2rem;

                path {
                    fill: rgba(0, 0, 0, 0.54)
                }
            }
        }

        .dateCont {
            display: flex;
            align-items: center;
            justify-self: center;

            > div {
                width: 100%;
                padding: .375rem .75rem;
                border: 1px solid #ced4da;
                outline: none;
                border-radius: 2rem !important;
                height: 3rem !important;
                border-color: #E9E9E9 !important;
                display: flex;
                align-items: center;
                margin: .5rem 0;
            }

            > div > div {
                width: 100%;
            }

            :global(.dx-texteditor-input) {
                color: #495057!important;
                opacity: 1!important;
                font-family: Cairo-Bold!important;
                font-size: 0.8rem !important;
            }
        }

        .Tabs {
            @media (max-width: 767px) {
                position: fixed;
                bottom: 0;
                right: 0;
                left: 0;
                z-index: 99;
                background: $psi;
                padding: 15px 30px;

                .tabList {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    flex-wrap: wrap;

                    .activeTab {
                        background-color: $alpha;
                        border-radius: 20px;

                        .tab {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}

@import './../../../global/style/theme.scss'
