@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .title {
            font-size: 0.9rem;
            font-family: Cairo-Bold;
            margin-bottom: 0;
            color: $alpha;
            padding: 5px 5px 0;
        }

        .infoContainer {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }

        .municipList {
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;

            li {
                font-family: Cairo-Bold;
                font-size: 0.8rem;
                cursor: pointer;
                color: #687588;

                a {
                    padding: 7px 10px;
                    text-decoration: none;
                    border-radius: 20px;
                    display: block;

                    &:active, &:focus {
                        background-color: #F3F3F3;
                        color: $alpha;
                    }

                    // &:global(.active), &::focus {
                    //     background-color: #F3F3F3;
                    //     color: $alpha;
                    // }
                }
                
            }
        }

        .sideCard {
            @media (max-width: 767px) {
                display: none;
            }
        }

        .fixedDiv {
            display: none;
            @media (max-width: 767px) {
                display: block;
                position: fixed;
                bottom: 0;
                // top: 480px;
                // top: 90%;
                right: 0;
                left: 0;
                z-index: 99;
                background: $psi;
                padding: 15px 30px;
                display: flex;
                align-items: center;
                // font-family: GE_SS_Two_Medium;
                font-family: Cairo-Bold;
                font-size: .9rem;
            }
        }

        .Modal {
            text-align: center;
            background: $white;
            position: fixed;
            width: 290px;
            max-height: 400px;
            z-index: 9999;
            padding: 2rem;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            overflow: scroll;
        }

        .overlay {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: #00000057;
            z-index: 999;
        }
    }
}

@import './../../../../global/style/theme.scss'