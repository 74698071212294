@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .housing {

            &.backWrap {
                padding-bottom: 4rem;
                padding-top: 4rem;
                background: url('./../../../global/images/Group\ 4.png');
                position: relative;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0; right: 0;
                    top: 0; bottom: 0;
                    background: rgba($beta, 0.88);
                }
            }

            .heading {
                font-size: 1.44rem;
                font-family: Cairo-Bold;
                margin-bottom: 2rem;
                color: $omega;
            }

            .headerRow {
                margin-bottom: 4.7rem;

                .para {
                    font-size: 1.1rem;
                    font-family: Cairo-Bold;
                }
            
                .seemore {
                    background: $alpha;
                    width: 100%;
                    display: block;
                    font-size: 1rem;
                    font-family: Cairo-Bold;
                    color: #fff;
                    text-decoration: none;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    > div {
                        text-align: center;
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                    }

                    > div:nth-child(1) {
                        flex-basis: 100%;
                    }

                    > div:nth-child(2) {
                        flex-basis: 50%;
                        background: darken($alpha, 5%);
                    }

                    svg {
                        width: 2rem;
                        height: 2rem;
                        fill: #fff;
                    }

                    .engArrow {
                        transform: rotate(180deg);
                    }
                }
            }

            @media (max-width: 992px) {
                margin-top: 7rem;
            }
        }

        .sorting {
            margin-top: 5.5rem;
            padding-bottom: 3.5rem;
            font-family: Cairo-Bold;

            .title {
                font-size: 1.6rem;
            }

            .sortingBtn {
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                padding: 5px 0;

                .label {
                    font-size: .9rem;
                    margin-bottom: 0;
                    padding: 0 4px;
                    text-align: center;
                }
            }

            .firteringIcon {
                padding: 0 2px;
                cursor: pointer;
                width: 32px;
                height: 32px;
                display: inline-block;
                text-align: center;

                svg {
                    width: 1rem;
                    height: 1rem;
                }

                &:hover{
                    background-color: #fff;
                    border-radius: 50%;
                    
                    svg {
                        fill: #56753C;
                    }

                }
            }
        }

        .footerInfoIcon {
            svg {
                path {
                    fill: $alpha;
                }
            }
        }
    }
}

@import './../../../global/style/theme.scss'