@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .mapStyles {
            width: 100%;
            max-height: 33.7rem;
            height: 33.7rem;
            outline: none;
        }
        
        .sideSpan {
            background: $alpha;
            width: 20.8rem;
            height: 12.8rem;
            display: block;
            position: absolute;
            bottom: 0;
            right: 15%;
            color: $white;
            font-size: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 1rem;
            font-family: Cairo-Bold;

            @media (max-width: 767px) {
                font-size: .6rem;
            }

            p {
                margin-bottom: 0;
            }

            .title {
                font-size: 1.38rem;
                // font-family: GE_SS_Two_Bold;
                font-family: Cairo-Bold;
                margin-bottom: 10px;

                @media (max-width: 767px) {
                    font-size: .8rem;
                }
            }

            @media (max-width: 767px) {
                width: 14.8rem;
                height: 8.8rem;
            }

            @media (max-width: 575px) {
                width: 10.8rem;
                height: 7.8rem;
            }
        }

    }

}

@import './../../../../global/style/theme.scss'


