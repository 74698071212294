@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    .#{$theme-name} {

        .mainBtn {
            border: none;
            border-radius: 8px;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }

            &.lgBtn {
                width: 10.6rem;
                height: 3rem;
                font-size: 0.9rem;
            }

            &.smBtn {
                width: 5rem;
                height: 2rem;
                font-size: 0.8rem;
            }

            &.primarymainBtn {
                background: $alpha;
                color: $white;
                font-family: Cairo-Bold;

                &:disabled {
                    cursor: not-allowed;
                    background-color: #ddd;
                    color: #aaa;
                }
            }

            &.secondarymainBtn {
                background: $omega;
                color: $white;
                font-family: Cairo-Bold;

                &.outlined {
                    border: 1px solid;
                }
            }
        }

        .scrollBtn {
            position: fixed;
            left:50%;
            bottom: 2rem;
            transform: translate(-50%,0);
            z-index: 999999;
            & svg {
                opacity: .9;
                font-size: 2.5rem;
                cursor: pointer;
                color: lighten($alpha, 20%);
                border-radius:50%;
                box-shadow: 5px 5px 10px rgba(0,0,0,.5);
            }
        }
    }
}

@import './theme.scss'
