@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .arrows {
            display: flex;
            align-items: center;
            justify-content: end;

            span {
                cursor: pointer;
                align-items: center;
                justify-content: center;
                height: 40px;
                width: 40px;
                display: flex;
                border-radius: 50%;
                margin: 5px;

                &.active {
                    background: #00AAE3;
                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }

                &.disabled {
                    background: #F3F6FF;
                }

                svg {
                    width: 2.17rem;
                }
            }

            &.arrowsAr {
                span:last-child {
                    svg {
                        transform: scale(-1);
                    }
                }
            }

            &.arrowsEn {
                span:first-child {
                    svg {
                        transform: scale(-1);
                    }
                }
            }
        }

    }
}

@import './../../../../../global/style/theme.scss'
