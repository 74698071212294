@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .title {
            color: $omega;
            font-family: Cairo-Bold;
            font-size: 1.4rem;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }

        .invoice {

            .name {
                font-family: Cairo-Bold;
                font-size: .77rem;
                color: saturate($alpha, 60%);
                margin: 0;
                margin-bottom: 0;
                padding: 4px 0;
                text-align: center;
                width: 100%;
                background: #F3F5F1;
            }

            .file {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: .7rem;
                margin: .5rem 0 .5rem;
                box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;

                p {
                    padding: 0;
                    font-size: 0.8rem!important;
                    margin: 2px 5px!important;
                }

                .fileSvg {
                    height: 1.6rem;
                    width: 1.6rem;
                }

                svg {
                    padding: 4px 0;

                    path {
                        fill: $alpha;
                    }
                }

                .download {
                    cursor: pointer;

                    &.downloadAR {
                        margin-right: auto;
                    }

                    &.downloadEN {
                        margin-left: auto;
                    }
                }
            }

            .invoiceField {
                background: #F9F9F9;
                border: none;
                border-radius: .25rem;
                font-size: 0.8rem;
                height: 2.5rem;
                padding: 0.5rem;
                outline: transparent;
                margin-bottom: .5rem;
                text-align: center;
                height: auto;
                overflow: hidden;
                resize: none;

                &:read-only {
                    background: none;
                    font-size: 1rem;
                    font-family: Cairo-Bold;
                    padding-top: 0;
                    background: #F9F9F9;

                    &:global(.#{title}) {
                        font-family: Cairo-Bold;
                        font-size: 1.4rem;
                        margin: .5rem auto 1rem;
                        padding: 2rem;
                        background: $beta;
                        color: saturate($alpha, 50%);
                    }
                }
            }

        }
    }
}

@import './../../../global/style/theme.scss'

