@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .commonQuestions {

            .mainTitle {
                font-size: 1.44rem;
                font-family: Cairo-Bold;
                color: $omega;
                text-align: center;
                margin-top: 3rem;
                margin-bottom: 2rem;
            }

            .label {
                font-size: 1rem;
                font-family: Cairo-Bold;
            }

            .input {
                margin-bottom: 1rem;
                margin-top: .5rem;
                height: 3rem;
                border-radius: 0.2rem;
                outline: none;
                border: 1px solid #CACACA;
                padding: 0 8px;
            }

            .questionsCont {
                margin-top: 1rem;
                margin-bottom: 2rem;

                .accCard {
                    margin: 1rem auto;
                }

                .accHeader {
                    padding: 0;

                    .accBtn {
                        width: 100%;
                        height: 100%;
                        color: inherit;
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border: none;
                        background: transparent;
                        padding: .8rem;
                        outline: none;

                        .accArrow {
                            svg {
                                width: 1rem;
                                height: 1rem;

                                path {
                                    fill: $alpha;
                                }
                            }

                            &.upArrow {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }

            }

        }
    }
    
}

@import './../../../global/style/theme.scss'


