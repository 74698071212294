@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .title {
            color: #000;
            font-size: 1rem;
            text-align: start;
            text-transform: capitalize;
            margin-bottom: 0;
        }

        .card {
            border: none;
            border-radius: 0;
            border-bottom: 1px solid #F1F2F4;
            padding-bottom: 1rem;
        }

        .iconCont {
            transform: translateY(30px);

            .notifIcon {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: #FFEDEC;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .notif {
            width: calc(100% - 50px);
        }

        .accHeader {
            padding: 0;
            font-family: Cairo-Bold;
            font-size: .9rem;
            line-height: 1rem;
            border: none;

            .accBtn {
                width: 100%;
                height: 100%;
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: none;
                padding: .5rem;
                outline: none;

                > span {
                    text-align: initial;
                }

                &.accBtnReaded {
                    // background: #efefef;
                    background: #fff;
                    color: #333;

                    .accArrow {
                        svg {
                            path {
                                fill: #333;
                            }
                        }
                    }
                }

                &.accBtnNew {
                    // background: $alpha;
                    background: #fff;
                    color: #000;

                    .accArrow {
                        svg {
                            path {
                                fill: #fff;
                            }
                        }
                    }
                }

                .accArrow {
                    svg {
                        width: .8rem;
                        height: .8rem;

                        path {
                            fill: $alpha;
                        }
                    }

                    &.upArrow {
                        transform: rotate(180deg);
                    }
                }
            }

            .time {
                margin-bottom: 0;
                font-size: .7rem;
                margin-top: 5px;
                color: #687588;
                font-family: Cairo-Bold;
            }
        }

        .accBody {
            text-align: start;
            padding: 0.6rem;
            font-family: Cairo-Bold;
            font-size: .8rem;
            line-height: 1rem;
            color: #687588;

            .deleteNotifBtn {
                margin-top: 8px;
                padding: 5px;
                display: inline-block;

                svg {
                    width: 2rem;
                    height: 2rem;
                }
            }
        }

        .deleteBtns {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;
            margin-top: 1rem;
            width: 140px;
            height: 48px;
            border-radius: 6px;

            &.deleteReaded {
                background: #fff;
                color: #000;
                border: 1px solid #000;
            }

            &.deleteAll {
                background: #000;
                color: #fff;
                border: 1px solid #000;
            }

            &.confirm {
                background: $alpha;
                color: #fff;
                border: 1px solid $alpha;
            }

            &.cancel {
                background: #fff;
                color: $alpha;
                border: 1px solid $alpha;
            }
        }


        .arrows {
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                cursor: pointer;
                align-items: center;
                justify-content: center;
                height: 40px;
                width: 40px;
                display: flex;
                border-radius: 50%;
                margin: 5px;

                &.active {
                    background: $omega;
                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }

                &.disabled {
                    background: #F3F6FF;
                }

                svg {
                    width: 2.17rem;
                }
            }

            &.arrowsAr {
                span:last-child {
                    svg {
                        transform: scale(-1);
                    }
                }
            }

            &.arrowsEn {
                span:first-child {
                    svg {
                        transform: scale(-1);
                    }
                }
            }
        }
    }
}

@import './../../../../../global/style/theme.scss'
