@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .title {
            color: $omega;
            font-family: Cairo-Bold;
            font-size: 1.4rem;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            text-transform: capitalize;
        }

        .para {
            font-family: Cairo-Bold;
            font-size: 1rem;
        }
    }
}

@import './../../../global/style/theme.scss'

