.mapContainer {

    margin-top: 4.16rem;

    .title {
        font-size: 1.4rem;
        // font-family: GE_SS_Two_Bold;
        font-family: Cairo-Bold;
    }

    .para {
        font-size: 1rem;
        // font-family: GE_SS_Two_Medium;
        font-family: Cairo-Bold;
        margin-bottom: 3.5rem;
    }

    .mapRow {
        position: relative;
    }
}