@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .profile {

            .passwordIcon {
                height: 20px;
                width: 20px;
                position: absolute;
                transform: translateY(10px);
                cursor: pointer;
    
                &.passwordIconAR {
                    left: 30px;
                }
    
                &.passwordIconEN {
                    right: 30px;
                }
    
                .eyeSvg {
                    height: 20px;
                    width: 20px;
                }
            }

            .title {
                color: $omega;
                font-family: Cairo-Bold;
                font-size: 2.4rem;
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
            }

            .notice {
                color: $alpha;
                font-family: Cairo-Bold;
                // text-align: center;
                font-size: .9rem;
                margin-bottom: 0;
            }

            .profileCont{
                background: $psi;
                border-radius: 1rem;
                padding: 1rem;
                @media (max-width: 767.9px){
                    margin: 0;
                }
            }

            .imgMainContAr{
                border-bottom-left-radius: 1rem;
                overflow: hidden;
                background: #eee;
                @media (max-width: 767.9px){
                    border-radius: 0;
                }
            }

            .imgMainContEn{
                border-bottom-right-radius: 1rem;
                overflow: hidden;
                background: #eee;
                @media (max-width: 767.9px){
                    border-radius: 0;
                }
            }
            
            .imgCont {
                width: 200px;
                height: 200px;
                margin: 0 auto 1.5rem;
                border-radius: 50%;
                background: $white;
                overflow: hidden;

                img {
                    // height: 200px;
                    // width: 100%;
                    object-fit: cover;
                    // border-radius: 50%;
                }
            }

            .navAr {
                border-top-right-radius: .75rem;
                border-bottom-right-radius: .75rem;
                padding: .5rem .5rem .5rem 0;
                overflow: hidden;
                background: $white;
                @media (max-width: 767.9px){
                    border-radius: .75rem;
                    padding: .5rem;
                    margin-bottom:.5rem;
                }
            }

            .navEn {
                border-top-left-radius: .75rem;
                border-bottom-left-radius: .75rem;
                padding: .5rem 0 .5rem .5rem;
                overflow: hidden;
                background: $white;
                @media (max-width: 767.9px){
                    border-radius: .75rem;
                    padding: .5rem;
                    margin-bottom:.5rem;
                }
            }

            .nav {
                a {
                    font-family: Cairo-Bold;
                    color: #212529;
                    margin: .25rem;
                    text-align: center;
                    background: $white;
                    border: 1px solid $omega;
                    border-radius: .5rem;
                    padding: .75rem 0;
                }

                a[aria-selected="true"] {
                    border: 1px solid $alpha;
                    background: $alpha;
                    color: $white;
                }
            }

            .notes {
                background: $omega;
                padding: 1rem .5rem;
                margin-top: 1rem;
                margin-bottom: 1.5rem;
                width: 100%;
                color: #e20613;
                font-family: Cairo-Bold;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                    margin-bottom: 0;
                }
            }

            .infoContAr {
                background: $white;
                border-radius: .75rem .75rem 0 .75rem;
            }

            .infoContEn {
                background: $white;
                border-radius: .75rem .75rem .75rem 0;
            }

            .tabs {
                width: 100%;
                padding: 1rem;
                height: 100%;
            }

            .label {
                color: $alpha;
                font-family: Cairo-Bold;
                font-size: 0.9rem;
                margin-bottom: .5rem;
            }

            .input, .select {
                font-family: Cairo-Bold;
                border-radius: 4px;
                outline: none;
                border: 2px solid #CBD5E0 !important;
                background-color: transparent!important;
                margin-bottom: .5rem;

                &[data-file="file"] {
                    color: #FFF !important;
                }

                &:hover, &:focus {
                    border: 2px solid $omega!important;
                }

                @media (max-width: 767.9px){
                    text-align: center;
                }

                &:disabled {
                    opacity: 1;

                    &:hover {
                        border: 2px solid #CBD5E0 !important;
                    }

                    &[data-file="file"] {
                        background: #00B0EB;
                    }
                }
            }

            .searchSele, .selectDisabled {
                font-family: Cairo-Bold;
                border-radius: 4px;
                outline: none;
                border: 2px solid #CBD5E0 !important;
                background-color: transparent!important;
                margin-bottom: .5rem;

                > div {
                    border: none;
                    outline: none;
                    border-color: transparent!important;
                    padding: 0.2rem 0;
                    outline: none;
                    border-radius: 0.25rem;

                    > div {
                        justify-content: center;
                        color:#000 !important;
                        font-family: Cairo-Bold;
                        outline: none;
                        border-radius: 0.25rem;
                        border-color: transparent!important;
                    }
                }

                ::before {
                    border-bottom: none!important;
                }

                :global(.css-1uccc91-singleValue) {
                    font-family: Cairo-Bold;
                }

                &:hover {
                    border: 2px solid $omega!important;
                }
                
                &.selectDisabled {
                    opacity: 1;

                    &:hover {
                        border: 2px solid #CBD5E0!important;
                    }
                }
            }

            .input {
                padding: 0.6rem !important;
            }

            .select {
                padding: 0.4rem 0!important;
                height: 47px;
            }

            .datePicker, .datePickerDisabled {
                height: 47px;
                font-family: Cairo-Bold!important;
                border-radius: 4px;
                outline: none;
                border: 2px solid #CBD5E0 !important;
                background-color: transparent!important;
                margin-bottom: .5rem;

                :global(.dx-texteditor-input) {
                    font-family: Cairo-Bold!important;
                    font-size: 15px!important;
                }

                &:hover {
                    border: 2px solid $omega !important;
                }
            }

            .datePickerDisabled {
                opacity: 1;
                &:hover {
                    border: 2px solid #CBD5E0 !important;
                }
            }

            .phoneInput {
                > input {
                    font-family: Cairo-Bold;
                    border-radius: 4px;
                    outline: none;
                    border: 2px solid #CBD5E0 !important;
                    background-color: transparent!important;
                    margin-bottom: .5rem;
                    padding: 0.6rem !important;

                    &:hover, &:focus {
                        border: 2px solid $omega!important;
                    }

                    @media (max-width: 767.9px){
                        text-align: center;
                    }

                    &:disabled {
                        opacity: 1;

                        &:hover {
                            border: 2px solid #CBD5E0 !important;
                        }
                    }
                }
            }

            .editBtn {
                background: none;
                color: $alpha;
                border: none;
                font-family: Cairo-Bold;
                border-bottom: 2px solid $alpha;
                outline: none;
                font-size: 1.3rem;
                padding: 5px 0;
                // margin-bottom: 1rem;
            }

            .error {
                color: #e53e3e;
                font-size: .8rem;
            }

            .success {
                color: #155724;
                font-size: .8rem;
                font-family: Cairo-Bold;
            }

            .inputFile {
                opacity: 0;
                position: absolute; 
                z-index: 9;
                cursor: pointer;
                width: 85%;
                height: 45px;
            }

            .fileUploader {
                font-family: Cairo-Bold;
                border-radius: 4px;
                outline: none;
                border: 1px dashed $omega !important;
                background-color: transparent!important;
                // margin-bottom: .5rem;
                height: 47px;
                padding: 10px;
                color: $omega!important;
                margin-bottom: 10px;

                :placeholder-shown {
                    color: $omega!important;
                }

                &[value] {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &.fileUploaderEn {
                    padding-right: 15%;
                }

                &.fileUploaderAr {
                    padding-left: 15%;
                }
            }

            .uploadIcon {
                position: absolute;
                top: calc(50% - 5px);
                transform: translateY(-50%);

                &.uploadIconEn {
                    right: 25px;
                }

                &.uploadIconAr {
                    left: 25px;
                }

                svg {
                    path {
                        stroke: $omega;
                    }
                }
            }

            .files {
                background: $beta;
                padding: 6px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 8px;
                height: 30px;
                border-radius: 4px;

                .file {
                    font-size: 0.8rem;
                    margin: 0;
                    color: $alpha;
                    font-family: Cairo-Bold;
                }

                .downIcon{
                    cursor: pointer;
                    border-radius: 4px;
                    width:32px;
                    height: 32px;
                    line-height: 32px;
                    text-align: center;
                }
            }

            .dataNote {
                font-family: Cairo-Bold;
                margin-bottom: 2.5rem;
            }

            .saveBtn {
                background-color: $alpha;
                color: #fff;
                width: 140px;
                height: 50px;
                font-family: Cairo-Bold;
                border: 1px solid $alpha;
            } 

            .cancelBtn {
                background-color: #fff;
                color: $alpha;
                width: 140px;
                height: 50px;
                font-family: Cairo-Bold;
                border: 1px solid $alpha;
            }

            .changePassBtn {
                background-color: $omega;
                color: #fff;
                // width: 140px;
                padding: 1rem;
                height: 50px;
                font-family: Cairo-Bold;
                border: 1px solid $omega;
            }
        }
    }
}

@import "global/style/theme.scss";