@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .header {
            background: $alpha;
            color: $white;
            padding: 1rem 1rem;
            margin-bottom: 1.5rem;
            display: flex;
            align-items: baseline;
            justify-content: space-between;

            > div {
                font-size: 1.2rem;
                font-family: Cairo-Bold;
            }

            .closeBtn {
                background: transparent;
                border: none;
            }

            svg {
                width: 1rem;
                height: 1rem;
                
                path {
                    fill: $white
                }
            }
        }

        .ownerCont {
            border: 2px solid $alpha;
            border-radius: 0.25rem;
            padding: 1rem;

            .title {
                font-size: 1rem;
                font-family: Cairo-Bold;
                color: $alpha;
            }
        }

        
    }
}

@import './../../../global/style/theme.scss'

