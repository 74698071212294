@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        :global(.sticky) .dropdown {
            width: 100vw;
        }

        .dropdown {
            background-color: $white;
            position: fixed;
            // position: absolute;
            // right: 0;
            // left: 0;
            z-index: 99999999;
            top: 60px;
            padding: .5rem 0;
            width: 200px;
            visibility: hidden;
            max-height: 0;
            opacity: 0;
            box-shadow: 0 1rem 1rem rgba(0,0,0,.15)!important;

            @media (max-width: 768px) {
                top: 50px;
            }

            &.open {
                max-height: 500px;
                opacity: 1;
                visibility: visible;
                transition: max-height .5s ease-in-out, visibility .5s ease-in-out, opacity .7s ease-in-out;

                @media (max-width: 769px) {
                    position: absolute;
                    width: 100vw;
                    background: $psi;
                    box-shadow: none!important;

                    &.openAR {
                        right: unset;
                        left: 0;
                    }

                    &.openEN {
                        left: unset;
                        right: 0;
                    }
                }
            }

            .municipalLink {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: inherit;
                padding: .5rem 0;

                &:hover {
                    text-decoration: none;
                }

                .engArrow {
                    svg {
                        transform: rotate(180deg);
                    }
                }
    
                .municipalName {
                    margin-bottom: -2px;
                    font-size: 0.7rem;
                    // font-family: GE_SS_Two_Bold;
                    font-family: Cairo-Bold;
                }
            }
        }

        .dropdownOverlay {
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: red;
        }
    }
}

@import './../../../../../../global/style/theme.scss'
