@font-face {
	font-family: Cairo-Black;
	src: url(./../fonts/Cairo-Black.ttf);
}

@font-face {
	font-family: Cairo-Bold;
	src: url(./../fonts/Cairo-Bold.ttf);
}

@font-face {
	font-family: Cairo-ExtraLight;
	src: url(./../fonts/Cairo-ExtraLight.ttf);
}

@font-face {
	font-family: Cairo-Light;
	src: url(./../fonts/Cairo-Light.ttf);
}

@font-face {
	font-family: Cairo-Regular;
	src: url(./../fonts/Cairo-Regular.ttf);
}

@font-face {
	font-family: Cairo-SemiBold;
	src: url(./../fonts/Cairo-SemiBold.ttf);
}

@font-face {
	font-family: Cairo-Bold;
	src: url(./../fonts/FrutigerLTStd-Black.ttf);
}

@font-face {
	font-family: Cairo-BoldCn;
	src: url(./../fonts/FrutigerLTStd-BlackCn.ttf);
}

@font-face {
	font-family: Cairo-BoldItalic;
	src: url(./../fonts/FrutigerLTStd-BlackItalic.ttf);
}

@font-face {
	font-family: Cairo-Bold;
	src: url(./../fonts/FrutigerLTStd-Bold.ttf);
}

@font-face {
	font-family: Cairo-BoldCn;
	src: url(./../fonts/FrutigerLTStd-BoldCn.ttf);
}

@font-face {
	font-family: Cairo-BoldItalic;
	src: url(./../fonts/FrutigerLTStd-BoldItalic.ttf);
}

@font-face {
	font-family: Frutiger-Cn;
	src: url(./../fonts/FrutigerLTStd-Cn.ttf);
}

@font-face {
	font-family: Frutiger-ExtraBlackCn;
	src: url(./../fonts/FrutigerLTStd-ExtraBlackCn.ttf);
}

@font-face {
	font-family: Frutiger-Italic;
	src: url(./../fonts/FrutigerLTStd-Italic.ttf);
}

@font-face {
	font-family: Cairo-Regular;
	src: url(./../fonts/FrutigerLTStd-Light.ttf);
}

@font-face {
	font-family: Cairo-RegularCn;
	src: url(./../fonts/FrutigerLTStd-LightCn.ttf);
}

@font-face {
	font-family: Cairo-RegularItalic;
	src: url(./../fonts/FrutigerLTStd-LightItalic.ttf);
}

@font-face {
	font-family: Cairo-Bold;
	src: url(./../fonts/FrutigerLTStd-Roman.ttf);
}

@font-face {
	font-family: Frutiger-UltraBlack;
	src: url(./../fonts/FrutigerLTStd-UltraBlack.ttf);
}
