@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .title {
            font-size: 0.9rem;
            font-family: Cairo-Bold;
            margin-bottom: 12px;
            margin-top: 10px;
            color: $alpha;
        }

        .subtitle {
            font-family: Cairo-Bold;
            font-size: 0.9rem;
            margin-bottom: 10px;
            margin-top: 20px;
        }

        .para {
            font-size: 0.8rem;
            font-family: Cairo-Bold;
            margin-bottom: 30px;
            color: #687588;
        }

        .border {
            border-top: 1px solid #00000024;
            margin: 1rem 0;
        } 

        .contactData {
            display: flex;
            align-items: center;
            font-size: 0.8rem;
            margin-bottom: 20px;

            a {
                display: flex;
                color: #212529;
                cursor: pointer;

                &:hover {
                    text-decoration: none;
                }
            }

            svg {
                width: 1.3rem;
                height: 1.3rem;
            }

            .content {
                &.contentEn {
                    margin-left: 1.1rem;

                    @media (max-width: 767px) {
                        margin-left: 0;
                    }
                }

                &.contentAr {
                    margin-right: 1.1rem;

                    @media (max-width: 767px) {
                        margin-right: 0;
                    }
                }

                .header {
                    font-family: Cairo-Bold;
                    margin-bottom: 5px;
                    font-size: .9rem;
                }
            
                .data {
                    font-family: Cairo-Bold;
                    margin-bottom: 0;
                    word-break: break-all;
                    color: #687588;
                }
            }

            @media (max-width: 767px) {
                justify-content: center;
                flex-direction: column;

                span {
                    margin-bottom: .8rem;
                }
            }
        }

        .socialMedia {
            display: flex;
            padding: 0;
            list-style: none;
            justify-content: space-evenly;
            width: 90%;
            margin: 0 auto 20px;

            svg {
                width: 1.3rem;
                path {
                    fill: $alpha;
                }
            }
        }

        .technicalSupport {
            width: 100%;
            height: 2.8rem;
            font-size: 0.7rem;
        }

        .label {
            font-size: 0.8rem;
            padding: 0 15px;
            text-align: center;
            font-family: Cairo-Bold;
            width: 100%;
        }

        .tSupportBtn {
            height: 2.5rem;
            width: auto;
            font-size: 0.8rem;
        }

        .helpText {
            color: #dc3545;
            text-align: center;
            font-family: Cairo-Bold;
            margin-bottom: 10px
        }

    }
}

@import './../../../global/style/theme.scss'
