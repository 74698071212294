.errorAlert {
	position: fixed;
	z-index: 999999999;
	width: 30%;
	top: 1%;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

	@media (max-width: 767px) {
		width: 90%;
	}
}
.arabicError {
	left: 1%;
	text-align: start;
}

.englishError {
	right: 1%;
	text-align: end;
}

.header {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	color: #721c24;
	font-size: 1rem;
}

.listKey {
	font-size: 0.9rem;
	font-weight: 500;
	color: #721c24;
}

.listValue {
	font-size: 0.8rem;
}

.fullWidth {
	top: 0;
	width: 100%;
	left: 0;
	right: 0;
	border-radius: 0;
	div {
		margin-bottom: 0;
		margin-top: 5px;
		color: #333;
	}
}

.warning {
	background-color: #ffc107 !important;
}
