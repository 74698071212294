@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    .#{$theme-name} {

        .gCard {
            border-radius: 20px;
            box-shadow: 0 3rem 3rem rgba(0,0,0,.1);
            padding: 45px 35px 35px 35px;
            background: $white;

            &.services {
                padding: 45px 25px 35px 25px;   
                @media(max-width: 767px) {
                    padding: 20px 0;
                } 
            }
    
           
            @media(max-width: 767px) {
                box-shadow: none;
                border-radius: 20px;
                padding: 0;
            }
        }
    }
}

@import './theme.scss'
