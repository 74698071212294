@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .container {
            position: relative;
            transform: translateY(-20%);

            @media (max-width: 1024px) {
                transform: translateY(-10%);

            }
            @media (max-width: 767px) {
                padding-top: 5.3rem;
                padding-bottom: 5.3rem;
                transform: none;
            }

            @media (max-width: 575px) {
                padding-top: 2.3rem;
                padding-bottom: 3.3rem;
            }

            .cardContainer {
                background: $beta;
                padding: 4rem;
                color: #000F39;
                background-image: url(./../../../global/images/all-services-bg.png);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;

                &.cardContainerEn {
                    border-top-left-radius: 100px;
                }

                &.cardContainerAr {
                    border-top-right-radius: 100px;
                }
                @media (max-width: 767px) {
                    padding: 20px;

                }
            }

            .title {
                color: #000F39;
                font-family: Cairo-Bold;
                font-size: 3rem;
                line-height: 3rem;
                margin-bottom: 1rem;
                @media (max-width: 1024px) {
                    font-size: 2.5rem;

                }
            }

            .subtitle {
                color: #000F39;
                font-family: Cairo-Regular;
                font-size: 1rem;
            }
            
            .para {
                font-family: Cairo-Regular;
                font-size: 1.2rem;
            }

            .endtitle {
                color: $alpha;
                font-size: 1.5rem;
                line-height: 1.5rem;
                font-family: Cairo-Bold;
                margin-bottom: 0;
                @media (max-width: 767px) {
                    text-align: center;
                    margin-bottom: 20px;
                }
            }
            
            .background {
                background: url(./../../../global/images/All_Services.png);
                background-repeat: no-repeat;
                background-size: cover;
            }

            .servicesCard {
                // position: absolute;
                // top: 280px;    
                // top: 17.5rem;
                
                // @media (max-width: 992px) {
                //     // top: 300px;    
                //     top: 18.75rem;     
                // }

                // &.servicesCardAr {
                //     left: 15px;
                //     right: 40px;

                //     @media (max-width: 767px) {
                //         left: 40px;
                //         right: 40px;
                //     }
                // }

                &.servicesCardEn {
                    left: 40px;
                    right: 15px;

                    @media (max-width: 767px) {
                        left: 40px;
                        right: 40px;
                    }
                }

                > div {
                    padding: 0;
                    border-radius: 0;
                }
            }

            // .buttonContainer {
            //     position: absolute;
            //     background: $alpha;
            //     width: 18%;
            //     height: 8rem;
            //     display: flex;
            //     align-items: center;
            //     display: block;
            //     // transform: translateY(-55%);

            //     @media (max-width: 767px) {
            //         height: 7rem;
            //         width: 20%;
            //     }

            //     @media (max-width: 575px) {
            //         height: 6rem;
            //         width: 35%;
            //     }

            //     &.buttonContainerAr {
            //         left: 20%;   
                    
            //         @media (max-width: 992px) {
            //             left: 18%;
            //             // top: 76%;
            //         }

            //         @media (max-width: 767px) {
            //             left: 8%;
            //             // top: 85%
            //         }

            //         @media (max-width: 575px) {
            //             left: 12%;
            //             // top: 90%;
            //         }
            //     }

            //     &.buttonContainerEn {
            //         right: 20%;   
                    
            //         @media (max-width: 1199px) {
            //             // top: 79%;
            //         }

            //         @media (max-width: 992px) {
            //             // top: 83%;
            //         }

            //         @media (max-width: 767px) {
            //             right: 8%;
            //             // top: 90%;
            //         }

            //         @media (max-width: 575px) {
            //             right: 12%;
            //             // top: 92%;
            //         }
            //     }

            //     > div {
            //         padding: 0;
            //         height: 100%;
            //         width: 100%;
            //     }

            //     .allServicesBtn {
            //         display: flex;
            //         justify-content: center;
            //         flex-direction: column;
            //         align-items: center;
            //         text-decoration: none;
            //         height: 100%;
            //         width: 100%;

            //         &.allServicesBtnEn {
            //             svg {
            //                 transform: scale(-1);
            //             }
            //         }

            //         svg {
            //             width: 2.17rem;

            //             @media (max-width: 767px) {
            //                 width: 1.8rem;
            //             }

            //             path {
            //                 fill: $white;
            //             }
            //         }

            //         p {
            //             margin: 0;
            //             margin-top: 15px;
            //             font-size: 1.38rem;
            //             // font-family: GE_SS_Two_Bold;
            //             font-family: Cairo-Bold;
            //             color: $white;

            //             @media (max-width: 992px) {
            //                 font-size: 1rem;
            //             }

            //             @media (max-width: 767px) {
            //                 font-size: 0.9rem;
            //             }
            //         }
            //     }
            // }
            .allServicesBtn {
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: Cairo-Bold;
                color: #fff;
                width: 250px;
                height: 64px;
                border-radius: 12px;
                font-size: 18px;
                line-height: 18px;
                background: linear-gradient(89.28deg, #0F257B -9.04%, rgba(63, 15, 123, 0.82) 126.11%);

                p {
                    margin-bottom: 0;
                    padding: 6px;
                }

                &.allServicesBtnAr {
                    svg {
                        transform: scale(-1);
                    }
                }

                &:hover {
                    text-decoration: none;
                }
            }

            :global {
                @keyframes slideAR {
                    from {
                        opacity: 0;
                        transform: translateX(-80%);
                    }

                    to {
                        opacity: 1;
                        transform: translateX(0);
                    }
                }
            }

            :global {
                @keyframes slideEN {
                    from {
                        opacity: 0;
                        transform: translateX(80%);
                    }

                    to {
                        opacity: 1;
                        transform: translateX(0);
                    }
                }
            }

            :global(.servicesSlideAR) {
                animation: slideAR 2s;
            }

            :global(.servicesSlideEN) {
                animation: slideEN 2s;
            }
        }

    }

}

@import './../../../global/style/theme.scss'
