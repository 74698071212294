@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .mainTitle {
            font-size: 1.3rem;
            // font-family: GE_SS_Two_Bold;
            font-family: Cairo-Bold;
            margin-bottom: 0;
        }

        .title {
            font-size: 1rem;
            // font-family: GE_SS_Two_Bold;
            font-family: Cairo-Bold;
            margin-bottom: 0;
        }

        .serviceCenter,
        .MunicipalInfo {
            padding: 1rem;
            margin-bottom: 1rem;

            div {
                display: flex;
                align-items: center;
                margin-bottom: 6px;

                P {
                    margin-bottom: 0;
                    font-size: .8rem;
                    // font-family: GE_SS_Two_Medium;
                    font-family: Cairo-Bold;
                }

                span {
                    padding: 0 6px;
                    font-family: Cairo-Bold;
                    font-size: 15px;
                }
            }
        }

        .info {
            margin: 3rem 0 1rem;
            display: flex;
        }

        .hideInfo {
            display: none;
        }

        .socialMedia {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: flex-start;

            li {
                margin: 0 5px;
                padding: 0 3px;

                span {
                    svg {
                        width: 1.3rem;
                        height: 1.3rem;

                        @media (max-width: 767px) {
                            width: 1.5rem;
                            height: 1.5rem;
                        }

                        path {
                            fill: #000;
                        }
                    }
                }
            }
        }

        .log {
            width: 3.5rem;
            height: 3.5rem;
            object-fit: contain;
            margin: 0 10px;
        }
    }

}

@import './../../../../global/style/theme.scss'