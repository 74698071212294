@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .cardStyle {
            background-color: $white;
            z-index: 1;
            padding: 0;
            // margin: 1.5rem auto 1rem;
            border-radius: 0.25rem;
            margin-bottom: 50px;

            .heading {
                text-align: center;
                font-size: 1.3rem;
                font-family: Cairo-Bold;
                margin: .5rem 0;
                display: block;
            }
            
            .form {
                width: 100%;

                label {
                    margin-bottom: 6px;
                }

                select {
                    padding: 0!important;
                }

                .label {
                    color: $alpha;
                    font-family: Cairo-Bold;
                    font-size: 0.8rem;
                    text-transform: capitalize;
                }

                small.label {
                    padding: 3px 0;
                }

                .input {
                    font-family: Cairo-Bold;
                    border: 2px solid #CBD5E0 !important;
                    height: 55px;
                    border-radius: 0.25rem;
                    border: none;
                    padding: 16px 20px !important;
                    font-size: 16px;
                    text-align: center;
                    background: $white;
                    margin-bottom: 10px;
                    outline: none;

                    &:hover {
                        border: 2px solid $omega !important;
                    }
                }

                .phoneInput {
                    > input {
                        font-family: Cairo-Bold;
                        border-radius: 4px;
                        outline: none;
                        border: 2px solid #CBD5E0 !important;
                        background-color: transparent!important;
                        margin-bottom: .5rem;
                        padding: 0.6rem !important;
    
                        &:hover, &:focus {
                            border: 2px solid $omega!important;
                        }
    
                        @media (max-width: 767.9px){
                            text-align: center;
                        }
    
                        &:disabled {
                            opacity: 1;
    
                            &:hover {
                                border: 2px solid #CBD5E0 !important;
                            }
                        }
                    }
                }

                .error {
                    z-index: 1;
                    color: #e53e3e;
                    font-family: Cairo-Bold;
                    font-size: 12px;
                    text-transform: capitalize;
                }

                .checkbox, .radioLabel {
                    font-size: 12px;
                    font-family: Cairo-Bold;
                    color: $alpha;
                }

                .radioBtn {
                    margin: 0!important;
                }
                
                .labelCenter {
                    text-align: center!important;
                }
                
                .radioGroup {
                    display: flex;
                    flex-direction: row!important;
                    justify-content: space-evenly!important;
                }

                .formBtn {
                    width: 100%;
                    padding: .5rem 0;
                    margin: 1rem 0 1rem;
                    border: 0;
                    border-radius: 0.25rem;
                    font-family: Cairo-Bold;
                    font-weight: 300;
                    text-transform: uppercase;
                }

                .primBtn {
                    background: #1B2124;
                    color: $white;
                    &.outline {
                        font-family: Cairo-Bold;
                        background: transparent;
                        color: #1B2124;
                        border: 1px solid #1B2124;
                        font-weight: bold;
                        font-size: 16px;

                    }
                }

                .secBtn {
                    border: 1px solid $alpha;
                    color: $alpha;
                    background: $white;
                }

                .primBtn[data-cursor="not-allowed"],
                .primBtn:disabled {
                    cursor: not-allowed;
                    background-color: $psi;
                    color: #aaa;
                }

                .OTPResend {
                    color: $alpha;
                    background: transparent;
                    border: none;
                    font-family: Cairo-Bold;
                    font-size: .8rem;
                    margin-bottom: 0.5rem;
                }

                .OTPInput {
                    width: 3rem;
                    text-align: center;
                }

                .otpWrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    direction: ltr;

                    label {
                        display: flex;
                        justify-content: center;
                    }
                }

                .countdown {
                    font-weight: 500;
                    color: #ff0000;
                    font-size: 1rem;
                    text-align: center;
                }

                .dealers {
                    margin: .5rem 0 0;
                    padding: 0;
                    list-style: none;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;

                    .dealer{
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        border-radius: 0.25rem;
                        padding: 2px;
                        margin: 4px;
                        border: 1px solid $alpha;

                        .imgwrapper {
                            width: 1.3rem;
                            height: 1.3rem;
                            background: $alpha;
                            border-radius: 0.25rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 5px;

                            .dealerImg {
                                width: 1rem;
                                height: 1rem;
                            }
                        }

                        p {
                            margin: 5px 0;
                            padding: 0 5px;
                        }

                        &:hover, &.activeDealer {
                            background: $alpha;

                            p {
                                color: $white;
                            }
                        }
                    }
                }

                .datePicker {
                    padding: 0.5rem 0.75rem !important;
                    font-size: 16px!important;
                    text-align: center;
                    background: #ffffff;
                    font-family: Cairo-Bold!important;
                    border-radius: 4px;
                    outline: none;
                    border: 2px solid #CBD5E0 !important;
                    background-color: transparent!important;

                    &:hover {
                        border: 2px solid $omega !important;
                    }

                    input {
                        font-family: Cairo-Bold!important;
                    }
                }

                .inputFile {
                    opacity: 0;
                    position: absolute; 
                    z-index: 9;
                    cursor: pointer;
                    // width: 85%;
                    height: 45px;
                }

                .fileUploader {
                    font-family: Cairo-Bold;
                    border-radius: 4px;
                    outline: none;
                    border: 1px dashed $omega !important;
                    background-color: transparent!important;
                    // margin-bottom: .5rem;
                    height: 47px;
                    padding: 10px;
                    color: $omega!important;
                    margin-bottom: 10px;
                    cursor: pointer;
    
                    :placeholder-shown {
                        color: $omega!important;
                    }

                    &[value] {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &.fileUploaderEn {
                        padding-right: 15%;
                    }
    
                    &.fileUploaderAr {
                        padding-left: 15%;
                    }
                }

                .uploadIcon {
                    position: absolute;
                    top: calc(50% + 6px);
                    transform: translateY(-50%);
    
                    &.uploadIconEn {
                        right: 25px;
                    }
    
                    &.uploadIconAr {
                        left: 25px;
                    }
    
                    svg {
                        path {
                            stroke: $omega;
                        }
                    }
                }
            }      
        }

        .info_wrapper {
            background-image: url("./../../global/images/login-bg.jpg");
            min-height: 100vh;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }

        .info_section {
            color: #fff;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            flex-direction: column;
            padding-inline-start: 20px;
            min-height: 100%;
            padding-block: 50px;
            h1 {
                font-size: 38px;
            }
        }
    
        .overlay {
            position: absolute;
            background: linear-gradient(74deg, rgba(15, 41, 128, 1) 4.52%, rgba(21, 71, 158, 0.7) 87.71%);
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }

        .form_box {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 15px;
            border: 2px solid rgba(16, 38, 124, 0.10);
            border-radius: 30px;
            margin-top: -150px;
            background-color: #fff;
        }
        @media (max-width: 767px) {
            .form_box {
                margin-top: 0;
            }
          }
        

        .form_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 100%;
            p {
                color: #5E6366;
                font-size: 16px;
                text-align: center;
                max-width: 90%;
                margin: 10px auto;

            }
        }

        .btn_wwrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        
        .brightIcon {
            background-color: rgba(0, 176, 235, 1);
            display: flex;
            align-items: center;
            width: 28px;
            border-radius: 4px;
            height: 28px;
            justify-content: center;
            margin-inline-start: 10px;
        }

        .passwordIcon {
            height: 20px;
            width: 20px;
            position: absolute;
            transform: translateY(10px);
            cursor: pointer;

            &.passwordIconAR {
                left: 30px;
            }

            &.passwordIconEN {
                right: 30px;
            }

            .eyeSvg {
                height: 20px;
                width: 20px;
            }
        }
    }
}

.Stepper_wrapper {
    .MuiMobileStepper-progress {
        width: 100%;
    }
}


@import './../../global/style/theme.scss'
