@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .Content {
            margin-top: 3rem;
            margin-bottom: 3rem;

            .firstTitle {
                font-size: 1.2rem;
                // font-family: GE_SS_Two_Medium;
                font-family: Cairo-Bold;
                color: $omega;
            }
        
            .secondTitle {
                font-size: 1.8rem;
                // font-family: GE_SS_Two_Bold;
                font-family: Cairo-Bold;
                margin-top: 1.38rem;
                margin-bottom: 5px;
            }
        
            .para {
                font-size: 1.24rem;
                // font-family: GE_SS_Two_Medium;
                font-family: Cairo-Bold;
                margin-bottom: 2.7rem;
                text-align: start;
            }
        }
    }
}

@import './../../../global/style/theme.scss'
