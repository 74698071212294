
@mixin textOverflow {
    max-width: 100%;
    white-space: nowrap;
    text-overflow:  ellipsis ;
}

.titleContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding:  10px;
    font-weight: bold;
    
    .title {
        display: flex;
        align-items: center;
        gap:10px;
       
    }
}

.container{
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-top: 5rem;
    padding: 20px;
}

.fileContent {
    font-size: 0.7rem !important;
    color: black !important;
    font-family: Cairo-Bold;

    &.clientRequestInfo {
      word-wrap: break-word;
      width:80% ;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
}

.textLabel{
    overflow: hidden;
}
  
.textExpandedLabel{
    overflow: hidden;
    // @include textOverflow();
}

.lineWay{
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' strokeWidth='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' strokeLinecap='square'/%3e%3c/svg%3e");
}

.totalVal {
    font-weight: bold;
    font-size: 16px;
    text-align: end;
    padding: 0 30px;
}