@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        :global(.sticky) .dropdown {
            width: 100vw;
        }

        .dropdown {
            background-color: $white;
            position: absolute;
            right: 0;
            left: 0;
            z-index: 9999;
            top: 60px;
            padding: .5rem 0;
            width: 100%;
            visibility: hidden;
            max-height: 0;
            opacity: 0;
            box-shadow: 0 1rem 1rem rgba(0,0,0,.15)!important;

            @media (max-width: 992px) {
                top: 52px;
            }

            &.open {
                visibility: visible;
                max-height: 500px;
                opacity: 1;
                transition: all .5s ease-in-out;

                @media (max-width: 768px) {
                    position: relative;
                    background: $psi;
                    box-shadow: none!important;
                    top: .4rem;
                }
            }

            .municipalLink {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: inherit;
                padding: .5rem 0;

                span {
                    svg {
                        path:nth-child(2) {
                            fill: $omega;
                        }
                    }
                }

                &:hover {
                    text-decoration: none;
                }

                .engArrow {
                    svg {
                        transform: rotate(180deg);
                    }
                }
    
                .municipalName {
                    margin-bottom: 0;
                    font-size: 0.8rem;
                    font-family: Cairo-Bold;
                    color: $alpha;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

@import './../../../../../../global/style/theme.scss'
