@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .title {
            color: $omega;
            font-family: Cairo-Bold;
            font-size: 1.2rem;
            margin-top: 1rem;
            margin-bottom: 1.5rem;
        }

        .name {
            font-family: Cairo-Bold;
            font-size: .77rem;
            color: saturate($alpha, 60%);
            margin: 0;
            margin-bottom: 0;
            padding: 4px 0;
            text-align: center;
            width: 100%;
            background: #F3F5F1;
        }

        .invoiceField {
            background: #F9F9F9;
            border: none;
            border-radius: .25rem;
            font-size: 0.8rem;
            height: 2.5rem;
            padding: 0.5rem;
            outline: transparent;
            margin-bottom: .5rem;
            text-align: center;
            height: auto;
            overflow: hidden;
            resize: none;

            &:read-only {
                background: none;
                font-size: 1rem;
                font-family: Cairo-Bold;
                padding-top: 0;
                background: #F9F9F9;
            }
        }
    }
}

@import './../../../../global/style/theme.scss'
