.langBtn {
    border: 1px solid #fff;
    border-radius: 4px;
    background: transparent;
    color: #fff;
    font-family: Cairo-Bold;
    font-size: 0.85rem;
    padding: 6px;
    line-height: 0.85rem;

    .icon {
        &.engIcon {
            padding-left: 5px;
        }

        &.arIcon {
            padding-right: 5px;
            svg {
                margin-bottom: 2px;
            }
        }

        svg {
            width: 12px!important;
            height: 12px!important;
        }
    }
}

.blackTheme {
    width: max-content;
    border: 1px solid #1B2124;
    color: #1B2124;
}