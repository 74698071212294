.modalContainer {
    > div {
        width: 60vw!important;
        max-width: none;
        padding: 0;
        border-radius: 20px!important;
        box-shadow: 1px 10px 20px 0px rgba(0, 0, 0, 0.8);

        @media (max-width: 767px) {
            width: auto!important;
            margin: 1rem;
        }
    }
}

.modalData {
    flex-direction: column-reverse;
    padding: 0!important;
    border: none!important;

    > button {
        position: absolute;
        top: 5px;
        right: 10px;
        cursor: pointer;
    }
}

.imageContainer {
    position: relative;
    background-image: url(./../../../global/images/policyModal2.jpg);
    // background-image: url(./../../../global/images/policyModal.png);
    // background-image: url(./../../../global/images/offering1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-blend-mode: overlay;
    height: 100%;
    width: 50%;
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem;

    // &::after {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     background: linear-gradient(74.2deg, #0F2980 4.52%, #15479E 87.71%);
    //     opacity: 0.7;
    // }
}

.textContainer {
    width: 50%;
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > div {
        overflow: scroll;
    }

    p {
        font-family: Cairo-SemiBold;
        font-size: 16px;
        text-align: center;
        margin-bottom: 1rem;
    }
}

.launchUrBusinessBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Cairo-Bold;
    color: #fff;
    width: 100%;
    height: 50px;
    border-radius: 12px;
    font-size: 16px;
    line-height: 18px;
    background: linear-gradient(89.28deg, #0F257B -9.04%, rgba(63, 15, 123, 0.82) 126.11%);

    p {
        margin-bottom: 0;
        padding: 6px;
    }

    &:hover {
        text-decoration: none;
        color: #fff;
    }
}

.contentCont {
    height: 380px;
    display: flex;
    border-radius: .3rem;

    @media (max-width: 500px) {
        height: 80vh!important;
    }
}

