@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {
  :global(.#{$theme-name}) {
    .title {
      font-size: 0.9rem;
	    font-family: Cairo-Bold;
      color: $alpha;
      margin-bottom: 12px;
    }

    .dynamics > input,
    .dynamics > select,
    .dynamics > textarea,
    .dynamicTextValidation {
      font-family: Cairo-Bold;
      border-color: transparent;
      box-shadow: 0 3px 8px rgba(9, 32, 77, 0.12),
        0 0 2px rgba(29, 17, 51, 0.12);
      border-radius: 0.25rem;
      border: none;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 1rem 0.75rem;
      font-size: 0.688rem;
      text-align: initial;
      &:focus {
        border-color: transparent !important;
        box-shadow: 0 3px 8px rgba(9, 32, 77, 0.12),
          0 0 2px rgba(29, 17, 51, 0.12) !important;
      }
    }

    .dynamics > select {
      padding: 0 0.75rem 0 !important;
      font-family: Cairo-Bold;
      background: #fff !important;
    }

    .checkBox {
      height: auto;
      width: auto;
      margin-bottom: 0;
    }

    .terms {
      display: flex;
      align-items: center;
      font-family: Cairo-Bold;
      font-size: 0.8rem;
      padding: 0 15px;
    }

    .label {
      color: #00000077;
      font-family: Cairo-Bold;
      font-size: 0.8rem;
    }
    
    .required {
      color: rgb(220, 53, 69);
      margin: 0 0.125rem;
    }

    .onError {
      color: rgb(220, 53, 69);
      font-family: Cairo-Bold;
      font-size: 0.65rem;
    }

    .validationSuccess {
      color: rgb(88, 116, 58);
      font-family: Cairo-Bold;
      font-size: 0.65rem;
      margin-right: 0.5rem;
    }

    .validationError {
      color: #E42829;
      font-family: Cairo-Bold;
      margin-right: 0.5rem;
      font-size: 0.65rem;
    }

    .validationStateFont {
      font-size: 0.8rem;
    }

    .validationSuccess:nth-child(2),
    .validationError:nth-child(2) {
      font-size: 0.8rem;
      margin-top: 0.3rem;
    }

    .text {
      font-family: Cairo-Bold;
      color: #00000066;
      font-size: 0.65rem;
    }

    .textValidationContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .cursorPointer {
      cursor: pointer !important;
    }

    .showViolations{
      cursor: pointer !important;
      color: #58743a;
      text-decoration: underline;
    }

    .dynamicDropDown {
      width: 100%;
      background-color: white;
      box-shadow: 0 3px 8px rgba(9, 32, 77, 0.12),
        0 0 2px rgba(29, 17, 51, 0.12);
      border-radius: 0.25rem !important;
      border-color: transparent !important;
      position: relative;
      height: calc(1.5em + 0.75rem + 2px);

      > div {
        > div {
          // padding: 0.39rem !important;
          padding-right: 0.6rem !important;
        }
      }
    }

    .hiddenInput {
      position: absolute;
      opacity: 0;
      z-index: 0;
      width: 0;
      height: 0;
    }

    .checkBoxContainer {
      display: flex;
      height: 100%;
      align-items: center;
      .checkBox {
        & input {
          width: 20px;
          height:20px;
          outline: none;
          &:checked{
            accent-color: $alpha;
          }
        }
      }
    }
  }
}
@import "global/style/theme.scss";
