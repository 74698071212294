@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .InnerTitleCont {
            background-image: url('./../../../global/images/header-bg.png');
            background-position: bottom;
            height: 400px;
            padding-top: 100px;
            // height: 267px;
            position: relative;
            color: $white;
            display: flex;
            align-items: center;

            @media(max-width: 767px) {
                height: 200px;
            }

            &::before {
                content: "";
                position: absolute;
                left: 0; right: 0;
                top: 0; bottom: 0;
                background: rgba($alpha, 0.77);
            }

            .panelContainer {
                transform: translateY(-25px);

                @media(max-width: 767px) {
                    transform: none;
                }

                .breadCrumbs {
                    font-size: 0.9rem;
                    line-height: 0.9rem;
                    font-family: Cairo-Regular;
                    color: #c2c4c7;
                    text-transform: capitalize;

                    > a, a:hover {
                        color: #c2c4c7;
                        text-decoration: none;
                        cursor: pointer;
                    }

                    @media(max-width: 767px) {
                        text-align: center;
                    }
                }
            
                .title {
                    font-family: Cairo-Bold;
                    margin-bottom: 0;
                    font-size: 2rem;
                    text-transform: capitalize;

                    @media(max-width: 767px) {
                        font-size: .9rem;
                        text-align: center;
                    }
                }

                .show {
                    display: flex;
                }

                .hide {
                    display: none;
                } 
            }
        }
    }
}

@import './../../../global/style/theme.scss'
