@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    .#{$theme-name} {
        .pageContainer {
            position: relative;
            margin-top: -50px;

            &::before {
                content: ' ';
                width: 100%;
                height: 100%;
                position: fixed;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background-color: $beta;
                z-index: -1;
            }

            @media(max-width: 767px) {
                margin-top: 2rem;
                &.dashboard-container {
                    margin-top: 8rem;
                }
            }
        }
    }
}

@import './theme.scss'