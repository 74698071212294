@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .service {
            margin-bottom: 1rem;

            a {
                display: flex;
                text-decoration: none;
                align-items: center;
                cursor: pointer;
                color: inherit;
                background-color: #f3f6ff;
                padding: 10px;
                border-radius: 10px;

                &:hover {
                    color: inherit;
                    text-decoration: none;
                }

                svg, .serviceImg {
                    width: 55px;
                    height: 55px;
                    // width: 2rem;
                    // height: 2rem;

                    path#Shape {
                        fill: $alpha
                    }
                }

                p {
                    font-family: Cairo-Bold;
                    font-size: 1rem;
                    margin-bottom: 0;
                    padding: 0 0.5rem;
                }
            }
        }
    }
}

@import './../../../../../global/style/theme.scss'

