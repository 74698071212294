@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .title {
            color: $omega;
            // font-family: GE_SS_Two_Bold;
            font-family: Cairo-Bold;
            font-size: 1.4rem;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }

        .error {
            color: #dc3545;
            font-size: 0.7rem;
        }

        .input {
            background: #F3F5F1;
            border: none;
            border-radius: .25rem;
            font-size: 0.8rem!important;
            font-family: inherit!important;
            height: 2.5rem;
            padding: 0.5rem;
            outline: transparent;
            margin-top: .5rem;
            margin-bottom: 1rem;
        }

        .label {
            margin-bottom: 0;
            font-size: 0.75rem!important;
            font-family: Cairo-Bold;
        }

        .defaultValue {
            color: #757575;
        }
    }
}

@import './../../../global/style/theme.scss'
