@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .sliderContainer {
            height: 700px;
            max-height: 700px;
            padding: 0 !important;
            background: #000;
            position: relative;

            @media (max-width: 767px) {
                height: 500px;
            }

            .carousel {
                height: 100%;

                >div,
                a {
                    height: 100%;
                }

                ol {
                    padding: 0;
                }

                .item {
                    height: 100%;

                    >img {
                        height: 100%;
                        object-fit: cover;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        // background: rgba($gamma, 0.85);
                        background: linear-gradient(74.2deg, #0F2980 4.52%, #15479E 87.71%);
                        opacity: 0.7;
                    }

                    .firstTitle {
                        font-size: 1.2rem;
                        // font-family: GE_SS_Two_Medium;
                        font-family: Cairo-Bold;
                        color: $omega;
                    }

                    .secondTitle {
                        font-size: 1.8rem;
                        // font-family: GE_SS_Two_Bold;
                        font-family: Cairo-Bold;
                        margin-top: 1.38rem;
                        margin-bottom: 5px;
                    }

                    .para {
                        font-size: 1.24rem;
                        // font-family: GE_SS_Two_Medium;
                        font-family: Cairo-Bold;
                        margin-bottom: 2.7rem;
                        text-align: start;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }

                   
                }

                .caption {
                    height: 100%;
                    top: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;

                    @media (max-width: 575px) {
                        align-items: center;
                    }
                }

                > a {
                    display: none;
                }
            }

            .video {
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    // background: rgba($gamma, 0.85);
                    background: linear-gradient(74.2deg, #0F2980 4.52%, #15479E 87.71%);
                    opacity: 0.7;
                }
            }

            .video>video {
                // object-fit: none;
                object-fit: cover;
                // object-fit: contain;
                // overflow-clip-margin: content-box;
                // overflow: clip;
            }

            .info {
                z-index: 9;
                font-family: Cairo-Bold;
                color: #fff;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                margin-top: 135px;

                @media (max-width: 767px) {
                    margin-top: 110px;
                }

                @media (max-width: 500px) {
                    margin-top: 6rem;
                }

                p {
                    margin-bottom: 0;
                }

                .subTitle {
                    color: $omega;
                    font-size: 1rem;
                }

                .title {
                    font-size: 2.5rem;
                    line-height: 3.4rem;
                    margin: 1rem 0;
                    width: 80%;

                    @media (max-width: 767px) {
                        width: 100%;
                        font-size: 1.5rem; 
                    }

                    @media (max-width: 500px) {
                        width: 100%;
                        font-size: 1rem; 
                        line-height: 2rem;
                    }

                }
                .titlEn {
                    width: 70% !important;
                    overflow: hidden;
                }

                .price {
                    font-size: 2rem;
                    margin-bottom: 4rem;
                }

                .infoFooter {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    > div {
                        display: flex;
                        align-items: center;
                    }

                    div:nth-child(2) {
                        display: flex;
                        padding: 0 1rem;

                        span {
                            padding: 0 1rem;
                            text-align: center;
                        }

                        span:nth-child(2) {
                            border-left: 1px dashed #CED0D8;
                            border-right: 1px dashed #CED0D8;
                        }
                    }

                    .header {
                        font-size: 1rem;
                    }

                    .body {
                        font-family: Cairo-Regular;
                        font-size: 1rem;
                        color: #CED0D8
                    }

                    .arrows {
                        display: flex;
                        align-items: center;
                        justify-content: end;
            
                        span {
                            cursor: pointer;
                            align-items: center;
                            justify-content: center;
                            height: 40px;
                            width: 40px;
                            display: flex;
                            border-radius: 50%;
                            margin: 5px;
                            padding: 0!important;
            
                            &.active {
                                background: #00AAE3;
                                svg {
                                    path {
                                        fill: #fff;
                                    }
                                }
                            }
            
                            &.disabled {
                                background: #F3F6FF;
                            }
            
                            svg {
                                width: 2.17rem;
                            }
                        }
            
                        &.arrowsAr {
                            span:last-child {
                                svg {
                                    transform: scale(-1);
                                }
                            }
                        }
            
                        &.arrowsEn {
                            span:first-child {
                                svg {
                                    transform: scale(-1);
                                }
                            }
                        }
                    }
                    @media (max-width: 767px) {
                        display: none;
                        // .infoFooter {
                        // }
                    }
                }

                .verticalPara {
                    width: fit-content;
                    transform: rotate(-90deg);
                    position: absolute;
                    top: 30%;
                    font-family: Cairo-Regular;
                    font-size: .9rem;
                    margin-bottom: 0;
                    letter-spacing: 2px;

                    &.verticalParaEN {
                        right: -70px;
                        @media (max-width: 767px) {
                            right: -120px;
                        }
                    }

                    &.verticalParaAR {
                        left: -70px;
                        @media (max-width: 767px) {
                            left: -120px;
                        }
                    }
                }

                .chartBtn {
                    position: absolute;
                    top: 6%;
                    background: $omega;
                    width: 55px;
                    height: 40px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.chartBtnEN {
                        right: 0;
                        border-top-left-radius: 50px;
                        border-bottom-left-radius: 50px;
                    }

                    &.chartBtnAR {
                        left: 0;
                        border-top-right-radius: 50px;
                        border-bottom-right-radius: 50px;
                    }
                }
            }
        }
        .carouselBtn {
            width: 16rem;
            height: 3rem;
            font-size: 1.1rem;
            position: relative;
            font-family: Cairo-Bold;
            margin-bottom: 20px;
            span {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 3.5rem;
                background: darken($alpha, 5%);
                border-radius: 0.3rem;
                position: absolute;
                top: 0;
                bottom: 0;

                svg {
                    width: 2rem;
                    height: 2rem;

                    :nth-child(2) {
                        fill: $white;
                    }
                }
            }

            &.carouselBtnAr {
                padding-left: 3.3rem;

                span {
                    left: 0;
                }
            }

            &.carouselBtnEn {
                padding-right: 3.3rem;

                span {
                    right: 0;

                    svg {
                        transform: scale(-1);
                    }
                }
            }
        }
    }
}

@import './../../../global/style/theme.scss'