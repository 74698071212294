@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {
    :global(.#{$theme-name}) {

        .mapContainer {
            margin-top: 4.16rem;

            .title {
                font-size: 2rem;
                font-family: Cairo-Bold;
                color: $alpha;
            }

            .para {
                font-size: 1rem;
                font-family: Cairo-Bold;
                margin-bottom: 3.5rem;
                color: $alpha;
            }

            .mapRow {
                position: relative;
            }
        }

    }
}

@import "./../../../global/style/theme.scss";
