@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .downloadApp {
            padding: 4rem 0 5rem;

            &.hasBackground {
                background: $psi;
            }

            .sharjahLogo {
                    width: 6.8rem;
                    height: 6.8rem;
                    background: $white;
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: 0 1rem 3rem rgba(0,0,0,.1);

                img {
                    height: 6.3rem;
                }
            }

            .title {
                font-size: 1.3rem;
                // font-family: GE_SS_Two_Bold;
                font-family: Cairo-Bold;
                color: $omega;
                margin-top: 2rem;
                margin-bottom: 1.2rem;
            }
    
            .para {
                font-size: 1rem;
                // font-family: GE_SS_Two_Medium;
                font-family: Cairo-Bold;
                margin-bottom: 2rem;
            }

            .btnsContainer {
                display: flex;

                a {
                    .downloadBtn {
                        height: 2.5rem;
                        width: 7.7rem;
                    }

                    &:last-child {
                        margin: 0 15px;
                    }
                }
            }

            .imgsContainer {

                @media(max-width: 767px) {
                    margin-top: 2.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .backPhone {
                    width: 30%;
                    position: relative;
                    z-index: 1;

                    @media(max-width: 767px) and (min-width: 488px) {
                        width: 20%;
                    }
    
                    &:first-child {
                        margin-left: -20px;
                    }
    
                    &:last-child {
                        margin-right: -8px;
                    }
                }
    
                .frontPhone {
                    width: 40%;
                    position: relative;
                    z-index: 99;

                    @media(max-width: 767px) and (min-width: 488px) {
                        width: 30%;
                    }
                }
            }
        }
    }
}

@import './../../../global/style/theme.scss' 