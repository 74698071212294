@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .Form, input, textarea, select {
            width: 100%;
        }

        .Form {
            display: flex;
            flex-wrap: wrap;
            margin: 1rem 0 3rem;

            span {
                color: #dc3545;
                font-size: 0.7rem;
            }

            input, textarea, select {
                border-radius: .25rem;
                font-size: 0.8rem!important;
                font-family: inherit!important;
                padding: 0.5rem;
                outline: transparent;
                margin-top: 1rem;
                border-radius: 4px !important;
                border: 2px solid #CBD5E0 !important;
                background-color: transparent !important;
                box-sizing: border-box;
                height: 50px;
            }

            .select {
                color: gray;
            }

            button {
                margin: auto;
                margin-top: 2.5rem;
            }
        } 

        .dismiss {
            color: inherit;
            padding: .2rem 1rem;
            cursor: pointer;

            svg {
                width: 0.7rem;
                height: 0.7rem;
            }
        }
    }
}

@import './../../../../global/style/theme.scss'