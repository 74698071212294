@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

  .#{$theme-name} {

    .dx-gridbase-container {
      border-radius: 20px;
      padding: 1rem;
      background-color: #FFF;
      margin-bottom: 1rem;
    }

    .dx-datagrid-content .dx-datagrid-table .dx-row .MuiFormControlLabel-root {
      margin-left: auto;
      margin-right: auto;
    }

    .dx-link.btn {
      padding: 14px !important;
      line-height: 2px !important;
      font-size: 12px !important;
      color: $white !important;
    }

    .dx-widget {
      font-family: 'Cairo', sans-serif!important;
    }

    .dx-datagrid-search-panel {
      height: 40px;
    }

    .dx-datagrid-toolbar-button {
      margin-left: 5px;
      margin-right: 5px;
      border-radius: 10px;
    }

    .dx-texteditor.dx-editor-outlined {
      border-radius: 10px;
    }

    .dx-datagrid-content .dx-datagrid-table {
      border-collapse: initial;
      border-spacing: 0 15px;
    }

    .dx-datagrid .dx-link {
      text-decoration: none;
      color: $alpha;
    }

    .dx-datagrid-header-panel, .dx-datagrid-header-panel .dx-toolbar {
      background-color: #FFF;
    }

    .dx-datagrid-headers, .dx-datagrid-header-panel, .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
      border-bottom: none;
    }

    .dx-datagrid-borders .dx-datagrid-rowsview, .dx-datagrid-headers + .dx-datagrid-rowsview {
      border-top: none;
    }

    .dx-datagrid-rowsview {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .dx-datagrid-filter-row {
      background-color: #F4F7FF !important;
      border-radius: 10px;
      overflow: hidden;
      padding: 10px;
    }

    .dx-datagrid-filter-row .dx-col-fixed {
      background-color: $alpha!important;
    }

    .dx-datagrid .dx-row > td {
      padding: 10px;
    }

    .dx-datagrid-headers .dx-datagrid-filter-row > td {
      padding: 10px 5px !important;
    }

    .dx-grid-ar .dx-datagrid-headers .dx-datagrid-filter-row > td:first-child, .dx-grid-en .dx-datagrid-headers .dx-datagrid-filter-row > td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
      padding-right: 10px !important;
    }

    .dx-grid-ar .dx-datagrid-headers .dx-datagrid-filter-row > td:last-child, .dx-grid-en .dx-datagrid-headers .dx-datagrid-filter-row > td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      overflow: hidden;
      padding-left: 10px !important;
    }

    .dx-datagrid-headers {
      // border-top-left-radius: 10px;
      // border-top-right-radius: 10px;
      font-weight: 700;
      color:$alpha;
    }

    .dx-datagrid-content .dx-datagrid-table .dx-row > td, .dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
      vertical-align:middle;
    }

    .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
      padding:10px 5px;
      text-align: center !important;
    }

    .dx-datagrid-borders > .dx-datagrid-pager {
      border-top:none;
    }

    .dx-datagrid button .MuiButton-startIcon, .dx-datagrid a .MuiButton-startIcon {
      margin-left: 4px !important;
      margin-right: 4px !important;
    }

    .dx-datagrid .MuiButtonBase-root:hover {
      color: $white;
    }

    // .dx-datagrid-content button{
    //     background-color:rgba(255,255,255,0.9)  !important;
    // }

    .dx-datagrid-rowsview .dx-row {
      background-color: $white;
    }

    .dx-datagrid .dx-row-lines > td, .dx-datagrid-rowsview .dx-row.dx-row-lines:first-child, .dx-datagrid-rowsview .dx-row.dx-row-lines:first-child>td, .dx-datagrid-rowsview .dx-row.dx-row-lines:first-child>tr>td {
      border-top: 1px solid $psi;
    }

    .dx-grid-ar .dx-datagrid .dx-row-lines > td:first-child, .dx-grid-en .dx-datagrid .dx-row-lines > td:last-child {
      border-right: 1px solid $psi;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .dx-grid-ar .dx-datagrid .dx-row-lines > td:last-child, .dx-grid-en .dx-datagrid .dx-row-lines > td:first-child {
      border-left: 1px solid $psi;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit:not(.dx-focused) .dx-link, .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused), .dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused) {
      background-color: $gamma;
    }

    .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td, .dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr:last-child > td {
      border-bottom: 1px solid $gamma;
      border-top: 1px solid $gamma;
    }

    .outlinedColumn {
      border-right: 1px solid $psi !important;
      border-left: 1px solid $psi !important;
    }

    .dx-datagrid-headers .outlinedColumn {
      border-right: none !important;
      border-left: none !important;
    }

    .dx-row-focused .outlinedColumn {
      border-right: 1px solid $gamma !important;
      border-left: 1px solid $gamma !important;
    }

    .dx-loadpanel-content {
      width: auto !important;
      height: auto !important;
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
      position: fixed !important;
      padding: 20px;
      border-radius: 50%;
    }

    .dx-loadpanel-message {
      display: none;
    }

    .dx-loadindicator {
      margin-bottom: 0;
    }

    .dx-loadindicator-icon .dx-loadindicator-segment {
      background: $alpha;
      border-radius: 50%;
    }

    .dx-loadindicator-icon {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .dx-grid-ar .dx-searchbox .dx-placeholder::before,.dx-grid-ar .dx-searchbox .dx-texteditor-input {
      padding-left: 8px;
      padding-right: 34px;
      text-align: right;
    }

    .dx-grid-en .dx-searchbox .dx-placeholder::before,.dx-grid-en  .dx-searchbox .dx-texteditor-input {
      padding-right: 8px;
      padding-left: 34px;
      text-align: left;
    }

    .dx-grid-ar .dx-searchbox .dx-icon-search {
      left:0;
    }

    .dx-grid-en .dx-searchbox .dx-icon-search {
      right:0;
    }

    .dx-grid-ar .dx-show-clear-button .dx-clear-button-area {
      left: auto !important;
      right: 0 !important;
      position: absolute;
    }

    .dx-grid-en .dx-show-clear-button .dx-clear-button-area {
      right: auto !important;
      left: 0 !important;
      position: absolute;
    }

    .dx-grid-ar .dx-searchbox .dx-placeholder {
      right:0;
      left: auto;
    }

    .dx-grid-en .dx-searchbox .dx-placeholder {
      left:0;
      right: auto;
    }

    .dx-grid-ar .dx-datagrid-filter-row .dx-menu {
      left: auto;
      right: 0;
      margin-left: auto;
      margin-right: -2px;
    }

    .dx-grid-ar .dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-placeholder::before,
    .dx-grid-ar  .dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-texteditor-input {
      padding-right: 32px !important;
      padding-left: 0;
    }

    .dx-grid-en .dx-toolbar .dx-toolbar-after {
      padding-left: 0;
      left:0;
      right:auto;
    }

    .dx-grid-en .dx-datagrid-search-panel {
      margin-left: 0;
    }

    .dx-calendar-navigator .dx-calendar-caption-button.dx-button .dx-button-content,
    .dx-calendar-navigator-next-month.dx-button .dx-icon, .dx-calendar-navigator-next-view.dx-button .dx-icon,
    .dx-calendar-navigator-previous-month.dx-button .dx-icon, .dx-calendar-navigator-previous-view.dx-button .dx-icon
    {
      color: $alpha;
    }

    .dx-pager .dx-pages .dx-page {
      margin-left: 5px;
      margin-right: 5px;
    }

    .dx-datagrid-pager {
      border-top: none;
    }

    .dx-pager .dx-page-sizes .dx-selection, .dx-pager .dx-pages .dx-selection {
      background-color: $alpha;
      color: $white;
      border-radius: 50%;
      text-align: center;
      width: 40px;
      height: 40px;
    }

    .dx-datagrid-rowsview .dx-datagrid-table .dx-row.dx-freespace-row > td, .dx-datagrid-rowsview .dx-datagrid-table .dx-row.dx-virtual-row > td {
      display: none;
    }

    .dx-datebox-wrapper {
      z-index: 9999999999!important;
    }

    .dx-scrollable-container {
      overflow-y: hidden!important;
    }

  }
  .dx-dropdowneditor-icon {
    color: #2c2276;
  }
  .dx-row.dx-header-row , .outlinedColumn.dx-datagrid-drag-action {
    background-color: #F4F7FF;
    font-size: 14px;
    color: #687588;
  }
  .dx-link.dx-link-icon {
    
    width: 30px !important;
    height: 30px !important;
    padding: 5px !important;
    border-radius: 5px !important;
    &.dx-icon-trash , &.dx-icon-edit, &.display-Btn {
      color: #FFF !important;
    }
  }
  .dx-icon-trash {
    background-color: #E03137;
  }
  .dx-icon-edit {
    background-color: #C1E18D;
  }
  .display-Btn {
    background-color: #337ab7;
    padding: 5px !important;
    border-radius: 5px !important;
    display: inline-flex;
    margin: 0 3px;
    cursor: pointer;

    > svg > path {
      stroke: #fff;
    }
  }
}


@import './../../../global/style/theme.scss'


