@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    .#{$theme-name} {
        .cardLink, .cardLink:hover {
            color: inherit!important;
            text-decoration: none!important;

            .houseCard {
                background-color: white;
                box-shadow: 15px 0px 30px 0px rgba(228, 228, 228, 0.7);
                margin-bottom: 3.5rem;
                cursor: pointer;

                .cardImg {
                    height: 13rem;
                    width: 100%;
                    object-fit: cover;
                }

                .cardInfo {
                    padding: 2rem 1rem;

                    .cardTitle{
                        font-size: 1rem;
                        font-family: Cairo-Bold;
                        margin-bottom: 1rem;
                    }

                    .cardPara {
                        font-size: 0.77rem;
                        font-family: Cairo-Bold;
                        margin-bottom: 0;
                    }
                }

                .cardFooter {
                    border-top: 1px solid #D8D8D8;
                    display: flex;
                    align-items: center;
                    padding-top: 0.8rem;
                    padding-bottom: 0.8rem;

                    .footerInfo {
                        font-size: 0.8rem;
                        font-family: Cairo-Bold;
                        flex-basis: 100%;
                        text-align: center;
                        display: flex;
                        flex-direction: column;

                        > span {
                            margin: 2px auto;
                        }
                    }

                    &.cardFooterEN {
                        div:nth-child(1) {
                            border-right: 1px solid #D8D8D8;
                        }

                        div:nth-child(3) {
                            border-left: 1px solid #D8D8D8;
                        }
                    }

                    &.cardFooterAR {
                        div:nth-child(1) {
                            border-left: 1px solid #D8D8D8;
                        }

                        div:nth-child(3) {
                            border-right: 1px solid #D8D8D8;
                        }
                    }
                }
            }
        }
    }
}

@import './theme.scss'
