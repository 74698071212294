@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .InnovationContainer {
            background-color: $beta;
            background-image: url(./../../../global/images/all-services-bg.png);
            background-repeat: no-repeat;
            padding: 2rem 0 1rem;
            margin: 5rem 0;
            @media (max-width: 767px) {
                text-align: left;
            }

            .title {
                color: $gamma;
                font-size: 3rem;
                line-height: 3rem;
                font-family: Cairo-Bold;
                @media (max-width: 1024px) {
                    font-size: 2rem;
                }
            }

            .subTitle {
                color: $gamma;
                font-size: 18px;
                line-height: 18px;
                font-family: Cairo-Bold;
                margin-top: 2rem;
            }

            .para {
                color: #0E0A23;
                font-size: 20px;
                font-family: Cairo-Bold;
            }

            .boxsContainer {
                display: flex;
                justify-content: space-between;

                @media (max-width: 1024px) {
                    flex-wrap: wrap;
                }
                @media (max-width: 767px) {
                    justify-content: center;
                }

                .box {
                    height: 190px;
                    width: 222px;
                    background: #fff;
                    border-radius: 12px;
                    margin: 1rem 0;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    svg {
                        margin-bottom: .5rem;
                    }

                    p {
                        font-family: Cairo-Bold;
                        margin: .5rem 0;

                        &:first-child {
                            color: $gamma;
                            font-size: 18px;
                        }

                        &:last-child {
                            color: #687588;
                            font-size: 14px;
                        }
                    }
                }
            }

            .footer {
                margin: 1rem 0;
                background: #EEF6FD;
                border-radius: 8px;
                padding: 2rem;

                .launchUrBusinessBtn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: Cairo-Bold;
                    color: #fff;
                    width: 250px;
                    height: 64px;
                    border-radius: 12px;
                    font-size: 18px;
                    line-height: 18px;
                    background: linear-gradient(89.28deg, #0F257B -9.04%, rgba(63, 15, 123, 0.82) 126.11%);

                    @media (max-width: 767px) {
                       margin-top: 20px;
                    }
    
                    p {
                        margin-bottom: 0;
                        padding: 6px;
                    }
    
                    &.launchUrBusinessBtnAr {
                        svg {
                            transform: scale(-1);
                        }
                    }
    
                    &:hover {
                        text-decoration: none;
                    }
                }

                .endtitle {
                    color: $alpha;
                    font-size: 1.5rem;
                    line-height: 1.5rem;
                    font-family: Cairo-Bold;
                    margin-bottom: 0;
                }
            }
        }
    }
}

@import './../../../global/style/theme.scss'