.wrapper {
  /* display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; */
}
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  background-color: #FFF;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-image: url("./../../../global/images/BG-Login.jpg"); */
}

.infoSection {
  background-image: url("./../../../global/images/login-bg.jpg");
}

.container::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* background: rgba(59, 59, 59, 0.85); */
}
