@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {
  :global(.#{$theme-name}) {

    :global(.sticky) .stickyIcon {
      svg {
        width: 16px;
        height: 16px;
      }
    }

    .list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      // width: 100%;
      height: 100%;

      @media (max-width: 1024px) {
        justify-content: flex-end;
        max-width: fit-content;
      }

      @media (max-width: 768px) {
        height: auto;

        li:nth-child(1) {
          order: 4;
        }

        li:nth-child(2) {
          order: 3;
        }

        li:nth-child(3) {
          order: 2;
        }

        li:nth-child(4) {
          order: 1;
        }
      }

      > li {
        text-align: center;
        cursor: pointer;
        position: relative;
        margin: 0 20px;
        height: 100%;
        display: flex;
        align-items: center;
        // margin-inline: 20px;
        @media (max-width: 1024px) {
          margin-inline: 5px;
        }

        @media (max-width: 768px) {
          height: auto;
          margin: 20px 15px;
        }


        &:last-child {
          width: auto;
          @media (max-width: 768px) {
            width: 100%;
            display: flex;
            justify-content: space-around;
            margin-top: 20px;
          }
        }

        span {
          @media (max-width: 767px) {
            button {
              // padding: 0;
            }
          }
        }

        &.enStyle {
          // margin-left: auto;
          margin-inline: 0;
        }

        &.arStyle {
          // margin-right: auto;
          margin-inline: 0;
        }
      }

      .siginIn {
        border-radius: 20px;
        height: 41px;
        background: $alpha;
        border: none;
        color: $white;
        font-size: 0.8rem;
        padding: 0 25px;
        font-family: Cairo-Bold;

        @media (max-width: 992px) {
          height: 35px;
          font-size: 0.7rem;
          padding: 0 20px;
        }

        &.signAr {
          margin-right: 10px;

          @media (max-width: 992px) {
            margin-right: 8px;
          }
        }

        &.signEn {
          margin-left: 10px;

          @media (max-width: 992px) {
            margin-left: 8px;
          }
        }
      }

      .icon {
        padding: 0 10px;

        @media (max-width: 992px) {
          padding: 0 8px;
        }

        // svg {
        //   width: 1.1rem;
        //   height: 1.1rem;

        //   @media (max-width: 992px) {
        //     width: 1rem;
        //     height: 1rem;
        //   }
        // }
      }

      .dealingType {
        font-family: Cairo-Bold;
        font-size: 0.625rem;
        height: 0;
      }

      .userWrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0 0.25rem;
      }

      .menuItem {
        font-size: 0.8rem;
        // font-family: GE_SS_Two_Bold;
        font-family: Cairo-Bold;
        cursor: pointer;
        height: 100%;
        z-index: 9999;

        @media (max-width: 992px) {
          font-size: 0.7rem;
        }

        >a {
          color: inherit;
          text-decoration: none;
          font-family: inherit;
          width: fit-content;
          text-align: center;
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0 7.5px;
          margin: auto;

          &:global(.active),
          &.activeLink {
            color: $alpha;
          }

          &:hover {
            background-color: $psi;

            @media (max-width: 768px) {
              background: transparent;
            }
          }
        }
      }

      @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
        justify-content: center;
        flex-wrap: wrap;

        &.respOpen {
          height: auto;
          visibility: visible;
          max-height: 500px;
          opacity: 1;
          transition: all 0.5s ease-in-out;
        }

        .menuItem {
          margin: 0.3rem 0;
          padding: 0.4rem 0;

          a {
            padding: 0.5rem;

            &.activeLink {
              background: $psi;
            }
          }
        }
      }
    }

    .fontDropdown {
      position: absolute;
      background: #fff;
      height: 80px;
      width: 40px;
      top: 2.2rem;
      border-radius: 0.25rem;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      z-index: 999;

      &.dropEn {
        left: 50%;
        transform: translateX(-50%);
      }

      &.dropAr {
        left: 50%;
        transform: translateX(-50%);
      }

      &.dropOpen {
          display: flex;
      }

      &.dropClose {
          display: none;
      }

      .fontIcon {
          padding-top: 2px;
          padding-bottom: 2px;

          &:hover {
              background: #EEEEEE;
          }

          svg {
              width: .8rem!important;
          }

          &.disabledBtn {
              opacity: 0.4;
              cursor: not-allowed;
          }
      }
    }

    .fontDropOverlay {
      background: transparent;
      position: fixed;
      height: 100%;
      width: 100%;
      inset: 0px;
      overflow-y: auto;
      z-index: 99;

      &.show {
          display: block;
      }

      &.hide {
          display: none;
      }
    }

    .AccIcon {
      background: #00B0EB;
      width: 28px;
      height: 28px!important;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@import "./../../../../../global/style/theme.scss";