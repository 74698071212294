@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .title {
            color: #111827;
            // font-family: GE_SS_Two_Bold;
            font-family: Cairo-Bold;
            font-size: 1.4rem;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            text-align: start;
        }

        .gridTitle {
            color: #323B49;
            font-family: Cairo-Bold;
            font-size: 20px;
            margin-top: 1.5rem;
            margin-bottom: 0;
        }

        .para {
            // font-family: GE_SS_Two_Medium;
            font-family: Cairo-Bold;
            font-size: 1rem;
        }

        .container {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 auto;
            padding: 1rem 1.5rem;
            background: white;
            border-radius: 10px;
            border: 2px solid rgba(15, 41, 128, 0.06);
            background: rgba(230, 243, 244, 0.00);
            transition: all 0.3s ease-in-out;
            &:hover {
                background-color: #0F257B;
                

                h5 , h6 {
                    color: #FFF;
                }
                path{
                    fill: #FFF;
                }
            }

            &:active {
                box-shadow: 0 0.2px 0.05px 0px #58743a;
                transform: scale(1);
            }

            h6 {
                font-family: Cairo-Bold;
                font-size: 32px;
                color: rgba(16, 38, 124, 0.48);
            }

            svg {
                color: lighten($alpha, 30%);
            }

            h5 {
                font-family: Cairo-Bold;
                font-size: 16px;
                color: #323B49;
                margin-bottom: 0;
                margin-inline-start: 10px;
            }

            span {
                font-family: Cairo-Bold;
                font-size: 1.5rem;
                color: #baa167;
            }
        }

        .dashboardIcon {
            width: 36px;
            // height: 36px;
            display: flex;
            align-content: center;
            justify-content: center;

            svg {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .Dashboard_card_Wrapper {
            background-color: #fff !important;
            border-radius: 16px;
            z-index: 1;
            position: relative;
            margin-bottom: 50px;
            &:first-of-type {
                margin-top: -100px;
            }

        }
    }
}

@import "global/style/theme.scss";