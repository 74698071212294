@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .title {
            // font-family: GE_SS_Two_Bold;
            font-family: Cairo-Bold;
            font-size: 0.8rem;
            text-transform: capitalize;

            @media (max-width: 575px) {
                text-align: center;
                margin-top: 1rem;
            }
        }

        .dates {
            // font-family: GE_SS_Two_Bold;
            font-family: Cairo-Bold;
            font-size: 0.8rem;
            text-align: center;
            display: flex;
            flex-direction: column;

            @media (max-width: 575px) {
                font-size: 0.6rem;
            }
        }

        .iconsContainer {
            z-index: 99999999;
            
            .fontIcon {
                padding: 0 15px;
                cursor: pointer;
                svg {
                    width: 1.2rem;
                    height: 1.2rem;
                }
            }
            
            .brightIcon {
                padding: 0 15px;
                margin: 0 15px;
                cursor: pointer;
                svg {
                    width: 0.8rem;
                    height: 0.8rem;
                }

                @media (max-width: 575px) {
                    margin: 0 5px;
                }
            }
        }

        .fontDropdown {
            position: absolute;
            background: #fff;
            height: 80px;
            width: 40px;
            top: 1.5rem;
            border-radius: 0.25rem;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
            align-items: center;
            justify-content: space-around;
            flex-direction: column;
            z-index: 999;

            &.dropEn {
                right: 6%;
            }

            &.dropAr {
                left: 6%;
            }

            &.dropOpen {
                display: flex;
            }

            &.dropClose {
                display: none;
            }

            .fontIcon {
                padding-top: 2px;
                padding-bottom: 2px;

                &:hover {
                    background: #EEEEEE;
                }

                svg {
                    width: .8rem!important;
                }

                &.disabledBtn {
                    opacity: 0.4;
                    cursor: not-allowed;
                }
            }

        }

        .fontDropOverlay {
            background: transparent;
            position: fixed;
            height: 100%;
            width: 100%;
            inset: 0px;
            overflow-y: auto;
            z-index: 99;

            &.show {
                display: block;
            }

            &.hide {
                display: none;
            }
        }
    }
}

@import './../../../../global/style/theme.scss'
