@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {
        .dealing {
            @media (max-width: 767px) {
                position: fixed;
                bottom: 0;
                right: 0;
                left: 0;
                z-index: 99;
                background: $psi;
                padding: 15px 30px;
                
                .types {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    flex-wrap: wrap;

                    :global(.active) {
                        background-color: $alpha;
                        color: $white;
                        border-radius: 20px;
                    }
                }
            }
        }

        .title {
            font-family: Cairo-Bold;
            font-size: 1.2rem;
            margin-bottom: 6px;
        }

        .types {
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;

            li {
                font-family: Cairo-Bold;
                font-size: 1rem;
                cursor: pointer;

                &:global(.active) {
                    // background-color: #F3F3F3;
                    color: $gamma;
                    // border-radius: 20px;
                }

                @media(max-width: 767px)  {
                    padding: 5px 10px;
                }

                &.disabledTab {
                    a {
                        cursor: default;
                        opacity: .2;
                    }
                }

                a {
                    color: inherit;
                    padding: 10px 0;
                    text-decoration: none;
                    border-radius: 20px;
                    display: block;
                    cursor: pointer;
                }
                
            }
        }
    }
}

@import './../../../../global/style/theme.scss'
