@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .searchInput {
            border-radius: 10px!important;
            height: 3rem!important;
            border-color: #E9E9E9!important;
            font-size: 0.8rem!important;
            font-family: Cairo-Bold;

            &:focus {
                box-shadow: none;
                border-color: #E9E9E9;
            }
        }

        .searchIcon {
            position: absolute;
            top: 0.6rem;

            &.searchIconAr {
                left: 2rem;
            }

            &.searchIconEn {
                right: 2rem;
            }

            svg {
                width: 1.3rem;
            }
        }

        .searchResults {
            font-size: 0.8rem;
            font-family: Cairo-Bold;
            color: #3232325c;
            margin: 0;
        }

        .searchKeys {
            font-size: 1rem;
            font-family: Cairo-Bold;
            background: #EEF3E8;
            padding: 0.5rem 1rem;
            border-radius: 8px;
            color: $alpha;
            margin: 0 .5rem;

            &:first-child {
                margin: 0;
            }
        }

        .serviceContainer {
            padding-top: 25px;
            padding-bottom: 25px;
            border-bottom: 1px solid #EDEDED;
            background-color: $beta;
            border-radius: 14px;
            margin-top: 1rem;
            padding-inline: 20px;

            .serviceTitle {
                font-size: 1.2rem;
                font-family: Cairo-Bold;
                margin-bottom: 10px;
                color: $gamma;
            }
            
            .serviceDesc {
                font-size: 1rem;
                font-family: Cairo-Bold;
                margin-bottom: 8px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* number of lines to show */
                -webkit-box-orient: vertical;
            }

            .serviceDetails {
                margin-bottom: 0;
                font-size: 1rem;
                font-family: Cairo-Bold;
                color: #fff;
                text-decoration: none; 
                padding: .7rem 1rem;
                border: 1px solid $gamma;
                background-color: $gamma;
                border-radius: 8px;
                margin-top: 10px;
            }
        }
    }
}

@import './../../../../global/style/theme.scss'

