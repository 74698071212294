@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .title {
            text-align: center;
            margin: auto;
            margin-bottom: 2rem;

            .offerings {
                color: $gamma;
                font-size: 18px;
                line-height: 18px;
                font-family: Cairo-Bold;
            }

            .offeringsTitle {
                font-family: Cairo-Bold;
                color: #000000;
                font-size: 3rem;
                line-height: 3rem;
            }

            .offeringsPara {
                color: #64626A;
                font-size: 20px;
                font-family: Cairo-Regular;
            }
        }

        .offeringCard {
            background-color: $beta;
            border-radius: 40px;
            padding: 3rem 1.5rem;
            padding-bottom: 0;
            color: #000;
            font-family: Cairo-Bold;
            margin: 1rem 0;

            @media (max-width: 767px) {
                padding: 1.5rem;

            }

            &.horizCard {
                background-image: url(./../../../global/images/all-services-bg.png);
                background-repeat: no-repeat;
            }

            .title {
                font-size: 1.3rem;
                text-align: start;
                padding: 2rem 10px 0 10px;
                margin-bottom: 0.5rem;
            }

            .para {
                font-size: 1rem;
                padding: 0 10px;
            }

            .image {
                position: relative;
                img {
                    width: 90%;
                    height: 300px;
                    margin-bottom: 1px;
                    display: block;
                    margin: auto;
                    object-fit: cover;
                    border-top-right-radius: 40px;
                    border-top-left-radius: 40px;
                }

                .cost {
                    background: #fff;
                    width: 240px;
                    position: absolute;
                    top: 50%;
                    text-align: center;
                    font-family: Cairo-Bold;
                    padding: 10px;

                    &.costEn {
                        right: -25px;
                    }

                    &.costAr {
                        left: -25px;
                    }

                    p {
                        margin-bottom: 0;

                        &:nth-child(2) {
                            font-size: 12px;
                        }
                    }
                }
            }

            .cardBottom {
                display: flex;
                align-items: baseline;
                justify-content: center;
                padding-bottom: 2rem;

                p {
                    margin-bottom: 0;
                    text-align: center;

                    &:last-child {
                        font-size: 12px;
                    }
                }
            }
        }

        .btn {
            a:hover {
                text-decoration: none;
            }
       
            .seeMore {
                background-color: $gamma;
                color: #55C3EF;
                border-radius: 40px;
                padding: 2rem;
                font-family: Cairo-Bold;
                margin: 1rem 0;
                height: 90%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                cursor: pointer;

                p {
                    font-size: 1.5rem;
                    margin-bottom: 0;
                }
                @media (max-width: 767px) {
                    font-family: Cairo-Bold;
                    padding: 1rem;
                    height: 64px;
                    border-radius: 12px;
                    line-height: 18px;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    p {
                        font-size: 1rem;
                        margin-bottom: 0;
                        line-height: 1.2rem;
                    }
                }
            }
        }

        .Accordion {
            background: $beta;
            margin: .5rem 0;
            border-radius: 10px;
            padding: 0 1rem;
            
            .title {
                border: 0;
                background: transparent;
                padding: 1.5rem 1rem;
                width: 100%;
                text-align: start;
                margin-bottom: 0px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .offerName {
                    padding: 0 10px;
                    font-family: Cairo-Bold;
                    font-size: 1.2rem;
                    color: #212529;
                }

                .collapseIcon {
                    svg {
                        width: 1.5rem;
                        height: 1.5rem;

                        path {
                            fill: gray;
                        }
                    }
                }
            }

            .body {
                border-top: 1px solid $psi;
                padding: 1.5rem 1rem;
                font-family: Cairo-Bold;

                .offerPrice {
                    background: $alpha;
                    color: #fff;
                    padding: 1rem .5rem;
                    border-radius: 5px;
                    width: 50%;
                    text-align: center;
                    position: absolute;
                    top: 50%;
                    right: 40%;
                    transform: translate(-50%);

                    &.offerPriceAR {
                        right: -40%;
                    }

                    &.offerPriceEN {
                        right: 40%;
                    }

                    .price {
                        span {
                            font-size: 1.4rem;  
                        }
                    }
                }

                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .desc {
                    color: #000;
                    font-size: 1.2rem;  
                }

                .location {
                    color: $alpha;
                    font-size: 1.2rem;  
                    padding: 0.5rem 0;
                }

                .benefits {
                    margin: 1rem 0;
                    span {
                        padding: 0 10px;
                    }
                }
            }
        }

        .arrows {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            padding: 2rem 0;

            span {
                cursor: pointer;
                align-items: center;
                justify-content: center;
                height: 40px;
                width: 40px;
                display: flex;
                border-radius: 50%;
                margin: 5px;
                padding: 0!important;

                &.active {
                    background: #00AAE3;
                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }

                &.disabled {
                    background: #F3F6FF;
                }

                svg {
                    width: 2.17rem;
                }
            }

            &.arrowsAr {
                span:last-child {
                    svg {
                        transform: scale(-1);
                    }
                }
            }

            &.arrowsEn {
                span:first-child {
                    svg {
                        transform: scale(-1);
                    }
                }
            }
        }
    }
}

@import './../../../global/style/theme.scss'
