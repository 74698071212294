@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .modal {
            z-index: 999999999999!important;
            padding-right: 0!important;

            &.fullModal {

                > div {
                    width: 100vw!important;
                    height: 100vh!important;
                    margin: auto!important;
            
                    @media (min-width: 576px) {
                        max-width: none!important;
                        margin: auto!important;
                    }
                }
        
                > div > div {
                    height: 100%!important;
                    overflow-y: scroll;
                }
            }

            .modalHeader {
                background: $alpha;
                color: $white;

                > div {
                    font-size: 1.2rem;
                    font-family: Cairo-Bold;
                }

                > button {
                    margin: inherit;
                    padding: .5rem;
                    color: $white;
                    opacity: 1;
                }
            }
        }

        :global(.modal-backdrop.show) {
            z-index: 99999999999;
        }
    }
}

@import './../../../global/style/theme.scss'