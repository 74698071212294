@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .wrapper {
            .violations, .suspensions {
                height: 42px;
                background: $alpha;
                display: flex;
                align-items: center;
                font-size: .9rem;
                font-family: Cairo-Bold;
                color: $white;
                cursor: pointer;
                position: fixed;
                z-index: 1040!important;
                width: 45px;
                transition: width .5s;
                transform: translateY(-50%);

                &:hover {
                    width: 130px;
                }

                &.dirAR {
                    box-shadow: 4px 4px 10px 0px rgba(0,0,0,0.11);
                    border-top-right-radius: 25px;
                    border-bottom-right-radius: 25px;
                    left: 0;
                }

                &.dirEN {
                    box-shadow: -4px 4px 10px 0px rgba(0,0,0,0.11);
                    border-top-left-radius: 25px;
                    border-bottom-left-radius: 25px;
                    right: 0;
                }

                .violationsIcon, .suspensionsIcon {
                    padding-left: .65rem;
                    padding-right: .65rem;

                    svg, path {
                        width: 25px;
                        height: 27px;
                        fill: $white;
                    }
                }
            }

            .violations {
                margin-bottom: 1rem;
                top: 60%;
            }

            .suspensions {
                top: 70%;
            }
        }

        :global(.vioSidebarStyle), :global(.suspSidebarStyle) {
            background-color: white!important;
            position: fixed!important;
            width: 70%;
            z-index: 9999999999 !important;
            box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 30px!important;
            @media (max-width: 767px) {
                width: 100%;
            }
        }

        :global(.vioSideBarEnStyle), :global(.suspSideBarEnStyle) {
            left: 0px!important; 
            right: unset!important; 
        }

        :global(.vioSideBarArStyle), :global(.suspSideBarArStyle) {
            right: 0px!important; 
            left: unset!important; 
            
            &:global(.vioSideBarArClose), &:global(.suspSideBarArClose) {
                transform: translateX(100%)!important;
            }
        }

        :global(.violOverlayStyle) { 
            background-color: transparent!important; 
            z-index: 999999999!important;
        }

        .gridTitle {
            color: $alpha;
            font-family: Cairo-Bold;
            font-size: 1.1rem;
            margin-top: 1.5rem;
            margin-bottom: 0;
        }
    }

    :global(.#{$theme-name} > div[role=navigation]) {
        inset: unset!important;
    }

}

@import './../../../global/style/theme.scss'