@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {
  :global(.#{$theme-name}) {
    .title {
      font-size: 1rem;
      font-family: Cairo-Bold;
      color: $alpha;
      margin-bottom: 12px;
    }

    .addRequestNote {
      margin-bottom: 10px;
      margin-top: -10px;
      font-size: 1rem;
      font-family: Cairo-Bold;
      color: $alpha;
    }

    .accBtn {
      background: transparent;
      width: 100%;
      border: none;

      .accArrow {
        svg {
            width: 1rem;
            height: 1rem;

            path {
                fill: $alpha;
            }
        }

        &.upArrow {
            transform: rotate(180deg);
        }
    }
    }

    .oldRequestFiles {
      background: #95b572e0!important;

      .fileContent {
        color: #fff!important;
      } 
    }

    .mapStyles {
      width: 100% !important;
      height: 20rem !important;
      position: relative !important;
      outline: none;
    }

    .content {
      font-size: 0.8rem;
      font-family: Cairo-Bold;
      margin-bottom: 40px;
    }

    .details {
      font-size: 0.8rem;
      color: #00000066;
      font-family: Cairo-Bold;

      @media(max-width: 323px) {
        font-size: 0.6rem;
      }
    }

    .detailsContent {
      background: $beta;
      border-radius: 8px;
      padding: 0.375rem 2rem;
      margin-bottom: 2rem;
    }

    .gCard {
      // &.request {
      //   @media (max-width: 767px) {
      //     padding: 0;
      //   }
      // }
      @media (max-width: 767px) {
        border-radius: 7px;
        padding: 0;
      }
    }

    .fileLabel {
      font-family: Cairo-Bold;
      position: relative;
      border-radius: 4px;
      outline: none;
      border: 1px dashed $omega!important;
      // background-color: #fff;
      height: 47px;
      padding: 10px;
      color: $omega!important;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .uploadIcon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;

      &.uploadIconEn {
        right: 15px;
      }

      &.uploadIconAr {
        left: 15px;
      }

      &.signatureEn {
        right: 60px;
        z-index: 999999;
      }

      &.signatureAr {
        left: 60px;
        z-index: 999999;
      }

      svg {
        path {
          stroke: $omega;
        }

        g {
          fill: $omega;
        }
      }
    }

    .fileLoader {
      animation: loading-rotate 0.8s linear infinite;
    }

    @keyframes loading-rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .files {
      background: #fff;
      padding: 6px;
      border-radius: 4px;
      word-wrap: break-word;
      word-break: break-all;
      margin-top: 10px;

      &.clientRequestInfo {
        background: $beta;
        flex-wrap: wrap;
      }
    }

    .fileIcon {
      padding: 0 5px;
      cursor: pointer;
    }

    .fileContent {
      font-size: 0.7rem !important;
      color: #00000066 !important;
      font-family: Cairo-Bold;

      &.clientRequestInfo {
        word-wrap: break-word;
        // max-width: 100%;
        // max-width: 150px;
        width: 100%;
        max-width: 320px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .step {

      .stepHeader {
        display: flex;
        margin-top: 15px;
        margin-bottom: 15px;

        .number {
          min-width: 1.4rem;
          height: 1.4rem;
          background: $alpha;
          border-radius: 0.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $white;
          font-size: 0.7rem;
        }

        .stepTitle {
          font-family: Cairo-Bold;
          font-size: 1rem;
          padding: 0 15px;
          color: $alpha;
        }
      }

      .contentContainer{
        background: $beta;
        border-radius: 20px;
        padding: 20px;
        overflow-y: scroll;
      }

      .contentContainer::-webkit-scrollbar {
        height: 1rem;
      }
    
      .contentContainer::-webkit-scrollbar-thumb {
        border-radius: 10px;
      }

      .completed {
        width: 43px;
        height: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background-color: $alpha;
        color: #fff;
        z-index:  1;
          & path {
          fill: white;
          }
        }

      .stepContent {
        background: $beta;
        border-radius: 8px;
        padding: 1rem;
      }

      .mapContent {
        background: $beta;
        border-radius: 8px;
        padding: 1rem;
      }

      .mapBtn {
        font-family: Cairo-Bold;
        font-size: 0.9rem;
      }

      .step > input,
      .step > select,
      .step > textarea {
        font-family: Cairo-Bold;
        border-color: transparent;
        box-shadow: 0 3px 8px rgba(9, 32, 77, 0.12),
          0 0 2px rgba(29, 17, 51, 0.12);
        border-radius: 0.25rem;
        border: none;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 1rem 0.75rem;
        font-size: 0.688rem;
        text-align: initial;
        &:focus {
          border-color: transparent !important;
          box-shadow: 0 3px 8px rgba(9, 32, 77, 0.12),
            0 0 2px rgba(29, 17, 51, 0.12) !important;
        }
      }

      .step > select {
        padding: 0 0.75rem 0 !important;
        font-family: Cairo-Bold;
        background: #fff !important;
      }

      .checkBox {
      
        margin-bottom: 0;
        width: 20px;
        height:20px;

     
    
        &:checked{
          accent-color: $alpha;
        }
   

        @media only screen and (max-width: 600px) {
          width: 50px;
          height: 50px;
          margin: 2px;
        }
      }

      .terms {
        display: flex;
        align-items: center;
        font-family: Cairo-Bold;
        font-size: 1rem;
        padding: 0 15px;
        color: $alpha;
        flex-direction: column;
        align-items: flex-start!important;
      }

      .termsPara {
        font-family: Cairo-Bold;
        font-size: 1rem;
        padding: 0 15px;
        color: $alpha;
        text-transform: capitalize;
      }

      .terms > p, .termsPara > p {
        margin: 0 4px 0 4px;
        padding: 0 10px 10px 0;
        text-transform: capitalize;

        @media(max-width: 601px) {
          padding: 10px;
        }

        & > a{
          color: $alpha;
        }

      }

      
     


      .label {
        color: $alpha;
        font-family: Cairo-Bold;
        font-size: 1rem;
        margin-top: 10px;
      }

      .required {
        color: rgb(220, 53, 69);
        margin: 0 0.125rem;
      }

      .text {
        font-family: Cairo-Bold;
        color: #00000066;
        font-size: 0.65rem;
      }

      .onError {
        color: rgb(220, 53, 69);
        font-family: Cairo-Bold;
        font-size: 0.65rem;
      }

      .translateBtn {
        color: $alpha;
        font-family: Cairo-Bold;
        font-size: 0.65rem;
        text-align: end;
        cursor: pointer;
      }

      .fileContainer {
        position: relative;
        & input[type="file"]{
          cursor: pointer  !important;
        }

        label {
          display: flex;
          align-items: center;
          justify-content: start;
          width: 100%;
          height: calc(1.5em + 0.75rem + 2px);
          font-size: 1rem;
          transition: border-color 0.15s ease-in-out;
          border-radius: 4px !important;
          border: 2px solid #CBD5E0 !important;
          box-shadow: none;

          &:focus {
            border-color: transparent;
          }
        }

        .file {
          position: absolute;
          top: 0;
          opacity: 0;
        }
      }
    }

    .accCard {
      border: none;

      .accHeader {
        padding: 0;
        background-color: #fff;
        border-bottom: none;

        .accBtn {
          width: 100%;
          height: 100%;
          color: inherit;
          background: #fff;
          border: none;
          outline: none;

          .accArrow {
            svg {
              width: 1rem;
              height: 1rem;

              path {
                fill: $alpha;
              }
            }

            &.upArrow {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    .clientRequestLabel {
      color: #00000077;
      font-family: Cairo-Bold;
      font-size: 0.8rem;
    }

    .fileDetails {
      background: #fff;
      padding: 10px;
      border-radius: 5px;
    }

    .itemsContainer {
      font-size: 16px;
      // display: flex;
      // flex-direction: column;
      // width: 48%;
      // padding: 0 30px;

      // @media (max-width: 1024px) {
      //   width: 90%;
      //   padding: 0;
      // }

      &.contAr {
        border-right: 1px dashed #e5e9e1;
        height: 100%;
      }

      &.contEN {
        border-left: 1px dashed #e5e9e1;
        height: 100%;
      }
  
      & div {
        display: flex;
        justify-content: flex-start;

        :first-child{
          font-weight: bold;
        }

        span:nth-child(2){
          margin-right: 20px;
        }

        & span {
          width: 50%;
        }
      }
    }

    .serviceName {
      font-size: 2rem;
      font-family: Cairo-Bold;
      width: 50%;
    }

    .bar {
      border-radius: 70px;
      background: #EBF8FF;
      height: 8px;
      margin: 1rem 0;

      div {
        background-color: $alpha;
        display: block;
        height: 100%;
        border-radius: 70px;
        transition: width 1s ease-in-out;
      }
    }

    .stepName {
      color: $omega;
      font-family: Cairo-Bold;
      font-size: .9rem;
      text-transform: capitalize;
    }

    .stepNo {
      font-family: Cairo-Bold;
      font-size: .9rem;
      text-transform: capitalize;
      color: $alpha;

      .totalSteps {
        color: #83898C;
      }
    }

    .customInput {
      border-radius: 4px !important;
      border: 2px solid #CBD5E0 !important;

      &:hover, &:focus {
        border: 2px solid $omega !important;
        box-shadow: none !important;
      }
    }
  }

  .dismiss {
    color: inherit;
    padding: .2rem 1rem;
    cursor: pointer;

    svg {
        width: 0.7rem;
        height: 0.7rem;
    }
  }

  .errMsg {
    color: #dc3545;
    font-weight: bold;
    font-size: 12px;
    margin-top: 5px
  }

  .collapseTitle {
    display: flex;
    align-items: flex-start;

    span {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #2c2276;
      font-size: 11px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .collapseStep {
    background: transparent;
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    margin-bottom: 1.5rem;

    p {
      margin-bottom: 0;
      padding: 0 15px;
      font-size: 1rem;
      font-family: Cairo-Bold;
      color: #2c2276;
    }

    .accArrow {
      svg {
        width: .7rem;
        height: .7rem;

        path {
          fill: #2c2276;
        }
      }

      &.upArrow {
        transform: rotate(180deg);
      }
    }
  }

}

@import "global/style/theme.scss";
