.wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
}
.error {
  z-index: 1;
  color: #e53e3e;
  font-family: Cairo-Bold;
  font-size: 12px;
  text-transform: capitalize;
}
.correct {
  position: absolute;
  top: 100%;
  font-size: 0.625rem;
  z-index: 1;
  color: green;
  font-family: Cairo-Bold;
}
