@mixin textOverflow {
  max-width: 100%;
  // white-space: nowrap;
  text-overflow:  ellipsis ;
}

@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {
  :global(.#{$theme-name}) {

    .inputTextContainer {
      position: relative;

      & input  {
        border-radius: 4px !important;
        border: 2px solid #CBD5E0 !important;
        background-color: transparent!important;
        box-sizing: border-box;
        // padding-left: 3rem;
        height: 50px!important;
        padding: 0.375rem 0.75rem;
    
        &:hover {
          border: 2px solid $omega!important;
        }
    
        &:placeholder-shown  {
          color: #B5B5C3;
          font-family: Cairo-Bold;
          @include textOverflow();
        }
          
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }
    
      & textarea  {
        border-radius: 4px!important;
        border: 2px solid #CBD5E0 !important;
        background-color: transparent!important;
        box-sizing: border-box;
    
        &:hover {
          border: 2px solid $omega!important;
        }
    
        &:placeholder-shown  {
          color: #B5B5C3;
          font-family: Cairo-Bold;
          @include textOverflow();
        }
    
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }
    
      // & ::-webkit-calendar-picker-indicator {
      //   color: rgba(0, 0, 0, 0);
      //   opacity: 1;
      //   display: block;
      //   background: url(iconsFolder/calendarIcon.svg) no-repeat;
      //   width: 20px;
      //   height: 20px;
      //   border-width: thin;
      // }
    
      & [type=date]{
        display: flex;
        flex-direction: row-reverse;
        padding-left: 0.9rem !important;
    
        &:focus {
          padding-left: 0.9rem !important;
        }
    
        &::-webkit-datetime-edit {
          display: flex;
          flex-direction: row-reverse;
          padding-right: 0.9rem;
        }
      }

      .phoneInput, .phoneInput:focus {
        outline: none;

        input, input:focus  {
          outline: none;
        }
      }
    
      .inputTextIcon {
        position: absolute;
        left: 0.9rem;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    
      .numberIconContainer {
        display: flex;
        flex-direction: column;
    
        & span {
          cursor: pointer;
        }
    
        & button {
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
    
          &:nth-child(1){
            margin: -3px 0;
          }
    
          &:nth-child(2){
            margin: -3px 0;
          }
        }
      }
    }

    .dynamicDropDownBox {
      width: 100%;
      background: #F5F5F5 !important;
      border-radius: 4px !important;
      border: 2px solid #CBD5E0 !important;
      background-color: transparent!important;
      box-sizing: border-box;
      height: 50px;
    }
    
    .dynamicDropDownBox:hover {
      border: 2px solid $omega!important;
    }

    .textLabel {
      font-family: Cairo-Bold;
      overflow: hidden;
      color: $alpha;
      min-height: 100px;
      display: flex;
      align-items: end;
    }
    
    .textExpandedLabel {
      font-family: Cairo-Bold;
      overflow: hidden;
      @include textOverflow();
      color: $alpha;
      min-height: 100px;
      display: flex;
      align-items: end;
    }

    .dateContainer {
      :global(.dx-datebox) {
        border-radius: 4px;
        border: 2px solid #cbd5e0;
        height: 50px !important;
        background: transparent;
      }
    }

    .violationContainer {
      padding: .5rem 0;
      position: relative;

      .loadingOverlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
      }

      .img {
        position: absolute;
        top: 5px;
        
        &.imgAR {
          left: 0;
          right: unset;
        }

        &.imgEN {
          right: 0;
          left: unset;
        }
      }
    }

    .errMsg {
      color: #dc3545;
      font-weight: bold;
      font-size: 12px;
    }
  }
}

@import "global/style/theme.scss";
