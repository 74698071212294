@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .title {
            font-size: 1.2rem;
            font-family: Cairo-Bold;
            color: $alpha;
            margin-bottom: 0;
            padding: 15px 10px;
            background: $beta;
            border-radius: 12px;
            margin-bottom: 10px;

            .fees {
                color: #000;
                margin-bottom: 0;
            }

            .feesVal {
                color: $alpha;
                font-size: 1.5rem;
                margin-bottom: 0;    
                direction: initial;        
            }
        }

        .content {
            font-size: 1rem;
            font-family: Cairo-Bold;
            margin-bottom: 40px;
            padding: 0 10px;
            color: #000;
            text-transform: capitalize;
        }

        .stepsList {
            margin-bottom: 0;
            list-style: none;
            padding: 0;
            
            .step {
                margin-bottom: 1rem;
                display: flex;
                align-items: center;

                .number {
                    min-width: 1.4rem;
                    height: 1.4rem;
                    background: $omega;
                    border-radius: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $white;
                    font-size: 0.7rem;
                }

                .stepTitle {
                    display: flex;
                    flex-direction: column;
                    padding: 0 15px;
                    text-transform: capitalize;

                    p {
                        font-size: 1rem;
                        font-family: Cairo-Bold;
                        margin-bottom: 0;
                    }

                    span {
                        font-size: 0.67rem;
                        color: #000;
                        text-transform: capitalize;
                    }
                }
            }

            .collapseStep {
                background: transparent;
                border: none;
                width: 100%;
                padding: 0;

                p {
                    margin-bottom: 0;
                    padding: 0 15px;
                    font-size: 1rem;
                    font-family: Cairo-Bold;
                }

                .accArrow {
                    svg {
                        width: .7rem;
                        height: .7rem;

                        path {
                            fill: #000;
                        }
                    }

                    &.upArrow {
                        transform: rotate(180deg);
                    }
                }
            }

            .collapseStepBody p {
                font-size: 0.67rem;
                color: #000;
                text-transform: capitalize;
                padding: 0 30px;
            }

            
        }

        .collapsePart {
            border: none;
            background: transparent;
            width: 100%;
            text-align: justify;
            padding: 0;
            background: $beta;
            border-radius: 12px;
            margin-bottom: 20px;

            > div {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
            }

            .title {
                padding: 15px;
                margin-bottom: 0;
            }

            .accArrow {
                svg {
                    width: .7rem;
                    height: .7rem;

                    path {
                        fill: #2c2276;
                    }
                }

                &.upArrow {
                    transform: rotate(180deg);
                }
            }
        }

        .startBtn {
            @media (max-width: 767px) {
                width: 100%!important;
            }
        }
    }
}

@import './../../../../global/style/theme.scss'