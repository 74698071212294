@import './global/style/card.scss';
@import './global/style/buttons.scss';
@import './global/style/fonts.scss';
@import './global/style/service_container.scss';
@import './global/style/housingCard.scss';

html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  #root {
    overflow-x: hidden;
  }
}

.ar-dir {
  &.MuiStepper-root {
    .MuiStepConnector-alternativeLabel {
      left: calc(50% + 16px) !important;
      right: calc(-50% + 16px) !important;
    }
  }
}
.MuiStepper-root {
  padding: .5rem 0 1rem !important;
  .MuiStepConnector-alternativeLabel {
    left: calc(-50% + 26px) !important;
  }
}
.MuiTypography-root, .MuiTypography-body2  {
  font-family: Cairo-Bold !important;
  &.MuiFormControlLabel-label {
    font-family: Cairo-Bold !important;
  }
}

.image-gallery-icon:hover{
  color: #b49c64 !important;
}

.dx-overlay-wrapper {
  z-index: 9999999999!important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  // background: $primary; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  // background: #5e5d5daf; 
  background: #b1afaf; 
}

.dx-list-item {
  position: relative!important;
  // width: auto!important;;
  text-align: start!important;
}