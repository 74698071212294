@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    :global(.#{$theme-name}) {

        .menu {
            padding: 20px 0;
            box-shadow: none;
            height: 106px;
            background: transparent;

            @media (max-width: 768px) {
                display: flex;
                justify-content: space-between;
            }

            @media (max-width: 768px) {
                height: 80px;
                background-color: $gamma;
                box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;            }
        
            &.sticky {
                position: fixed;
                top: 0;
                right: 0;
                left: 0;
                z-index: 999;
                padding-left: 15px;
                padding-right: 15px;
                z-index: 9999999;
            }

            &.menuWithbg {
                background-color: $gamma;
                box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
            }

            .icons {
                z-index: 99999999;
                display: flex;
                align-items: center;

                @media (max-width: 768px) {
                    display: none;
                }
            }

            .logo {
                text-align: center;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;

                    @media (max-width: 1024px) {
                        height: 100%;
                    }

                    @media (max-width: 575px) {
                        width: 50%;
                        object-fit: contain;
                        display: block;
                    }
                }
            }

            .userLogin {
                z-index: 99999999;
                @media (max-width: 768px) {
                    display: flex;
                    justify-content: flex-end;
                }

                @media (min-width: 769px) {
                    display: none;
                }
            }
        }
    }
}

@import './../../../../global/style/theme.scss'




