@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {
  :global(.#{$theme-name}) {

    .container {
      z-index: 99999;
    }
    .content {
      text-align: center;
      font-size: 1.25rem;
      font-family: Cairo-Bold;
    }

    .siginIn {
      border-radius: 20px;
      height: 41px;
      background: $alpha;
      border: none;
      color: $white;
      font-size: 0.8rem;
      padding: 0 25px;
      font-family: Cairo-Bold;
      margin-bottom: 1rem;
      margin-top: 1rem;

      @media (max-width: 992px) {
        height: 35px;
        font-size: 0.7rem;
        padding: 0 20px;
      }

      @media (max-width: 500px) {
        height: 30px;
        font-size: 0.6rem;
        padding: 0 15px;
        width: 100%;
      }

      &.signAr {
        margin-right: 10px;

        @media (max-width: 992px) {
          margin-right: 8px;
        }
      }

      &.signEn {
        margin-left: 10px;

        @media (max-width: 992px) {
          margin-left: 8px;
        }
      }
    }
  }
}

@import "global/style/theme.scss";
