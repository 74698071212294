@mixin mainTheme($theme-name, $alpha, $beta, $gamma, $white, $psi, $omega) {

    .mapStyles {
        width: 100%;
        max-height: 33.7rem;
        height: 33.7rem;
        outline: none;
    }
    .infoWindow {
        padding-right: 20px !important;
        padding-top: 10px !important;
    }

}

@import './../../../../global/style/theme.scss'


